import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Card, Icon, Item, Typography } from '@tucar/pit-stop';
import { useQuery, useReactiveVar } from '@apollo/client';

import { getAuthStore } from '../../../graphql/store';
import TopNav2 from '../../../components/ui/TopNav/newTopNav';
import GET_VERIFIED_LIST from '../../../graphql/querys/driver/getVerifiedList';
import LoadingProgress from '../../../components/ui/LoadingProgress';
import TutorialVideos from './tutorialVideos';

const checkSteps = ['bank', 'docs', 'info', 'plan', 'app'];

const OnBoardingResume = ({ goNextStep, goBack }) => {
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const [verifiedList, setVerifiedList] = useState();
  const [percent, setPercent] = useState(0);
  const { loading, data } = useQuery(GET_VERIFIED_LIST.query, {
    variables: { uid },
    ...GET_VERIFIED_LIST.policies,
  });

  useEffect(() => {
    if (data && data.getDriver && data.getDriver.verifiedList) {
      setVerifiedList(data.getDriver.verifiedList);
    }
  }, [data]);

  useEffect(() => {
    if (verifiedList) {
      let total = 0;
      for (let i = 0; i < checkSteps.length; i += 1) {
        const step = verifiedList[checkSteps[i]];

        if (step) {
          total += 1;
        }
      }

      setPercent(Math.round((total / checkSteps.length) * 100));
    }
  }, [verifiedList]);

  if (loading) {
    return (
      <Box
        style={{
          height: '100vh',
          width: '100vw',
          background: '#F0F0F3',
        }}
      >
        <TopNav2
          styleIcon={{ transform: 'rotate(180deg)' }}
          buttonIcon="loginOutlined"
          prevPage="/app/onboarding"
          title=""
          handlePageChange={() => {}}
        />
        <LoadingProgress />
      </Box>
    );
  }

  return (
    <Box
      style={{
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
      }}
    >
      <TopNav2
        styleIcon={{ transform: 'rotate(180deg)' }}
        buttonIcon="loginOutlined"
        prevPage="/app/onboarding"
        title=""
        handlePageChange={goBack}
      />
      <Card
        style={{
          padding: '19px 20px',
          marginInline: '30px',
          flexDirection: 'row',
          gap: '10px',
          alignItems: 'center',
        }}
      >
        <Item style={{ minWidth: '55px', minHeight: '55px' }}>
          {percent === 100 ? (
            <Icon name="doneOutlined" color="#0057B8" />
          ) : (
            <Typography style={{ color: '#0057B8', fontWeight: 600, fontSize: '20px' }}>
              {percent}%
            </Typography>
          )}
        </Item>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: '10px',
          }}
        >
          <Typography style={{ color: '#5B5D71', fontWeight: 600 }}>
            {percent === 100
              ? 'Espera la confirmación de tu ejecutivo para navegar en la app.'
              : 'Termina de completar tus datos'}
          </Typography>
          <Button
            size="small"
            onClick={goNextStep}
            style={{ width: 'fit-content', lineHeight: 1.2 }}
          >
            {percent === 100 ? 'Ver perfíl' : 'Completa tu perfil'}
          </Button>
        </Box>
      </Card>

      <TutorialVideos />
    </Box>
  );
};

OnBoardingResume.propTypes = {
  goNextStep: PropTypes.func,
  goBack: PropTypes.func,
};

OnBoardingResume.defaultProps = {
  goNextStep: () => {},
  goBack: () => {},
};

export default OnBoardingResume;
