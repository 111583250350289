import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useMutation, useReactiveVar } from '@apollo/client';
import { Dropdown, Button, BoxFlex, Loader } from '@tucar/pit-stop';
import { getAuthStore } from '../../../graphql/store';
import { getDriverStore, setSource } from '../../../graphql/store/driver';
import UPDATE_SOURCE from '../../../graphql/mutations/driver/updateSource';
import ErrorModal from '../../../components/ui/ErrorModal';
import HeaderSource from './HeaderSource';

const SourceStep = ({ goBack, goNextStep }) => {
  const [sourceValue, setSourceValue] = useState('');
  const [open, setOpen] = useState(false);
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const {
    info: { source },
  } = useReactiveVar(getDriverStore);

  const [updateSource, { loading, error, data }] = useMutation(
    UPDATE_SOURCE.mutation,
    UPDATE_SOURCE.policies,
  );

  const handleSource = () => {
    updateSource({
      variables: {
        uid,
        source: sourceValue,
      },
    });
  };

  useEffect(() => {
    if (source && source !== 'Facebook') {
      setSourceValue(source);
    }
  }, [source]);

  useEffect(() => {
    if (!loading) {
      if (data) {
        setSource(sourceValue);
        goNextStep();
      }
      if (error) {
        setOpen(true);
      }
    }
  }, [loading, data]);

  if (loading) {
    return (
      <Box sx={{ height: '100vh', width: '100vw', background: '#F0F0F3' }}>
        <HeaderSource goBack={goBack} />
        <Box sx={{ paddingTop: '20px', display: 'flex', justifyContent: 'center' }}>
          <Loader size={32} pointRadio="4px" />
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ height: '100vh', width: '100vw', background: '#F0F0F3' }}>
      <ErrorModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        content="Ups, ha ocurrido un error de nuestra parte. Contacta a soporte para resolver el problema o intenta nuevamente."
        title="Error al actualizar el canal"
      />
      <HeaderSource goBack={goBack} />

      <Box sx={{ paddingInline: '30px', paddingTop: '30px' }}>
        <Dropdown
          fullWidth
          label="Canal"
          value={sourceValue}
          options={[
            'Facebook o Instagram',
            'Google',
            'TikTok',
            'Uber',
            'Un amigo, familiar o conocido',
            'Una empresa reclutadora me contactó',
          ]}
          placeholder="Selecciona"
          onChange={(e) => setSourceValue(e)}
        />
      </Box>

      <BoxFlex flexDirection="column" gap="20px" style={{ paddingTop: '103px' }}>
        <Button
          style={{ width: 'min-content', alignSelf: 'center' }}
          disabled={sourceValue === ''}
          onClick={handleSource}
        >
          Continuar
        </Button>
      </BoxFlex>
    </Box>
  );
};

SourceStep.propTypes = {
  goBack: PropTypes.func,
  goNextStep: PropTypes.func,
};

SourceStep.defaultProps = {
  goBack: () => {},
  goNextStep: () => {},
};

export default SourceStep;
