import { gql } from '@apollo/client';

const GET_UBER_INFORMATION = {
  query: gql`
    query GetUber($uid: ID!) {
      getUber(uid: $uid) {
        _id
        category {
          discount
          uberPro
        }
        code
        email
        firstname
        lastname
        phone
      }
    }
  `,
  policies: {
    fetchPolicy: 'no-cache',
  },
};

export default GET_UBER_INFORMATION;
