import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import { useQuery, useReactiveVar } from '@apollo/client';
import moment from 'moment';
import { Loader } from '@tucar/pit-stop';
import { getAuthStore } from '../../../graphql/store';
import GET_LIQUIDATIONS from '../../../graphql/querys/balance/getLiquidations';
import LiquidationsBox from './liquidationBox';
import ResumeDetail from './resumeDetail';
import TopNav2 from '../../../components/ui/TopNav/newTopNav';
import EmptyView from '../../../components/extras/EmptyView';

function transformLiquidation(elem) {
  const now = moment();
  const input = moment(elem.date);
  const isThisWeek = now.isoWeek() === input.isoWeek();

  return {
    // eslint-disable-next-line no-underscore-dangle
    id: elem._id,
    amount: Math.abs(elem.balance),
    positive: elem.balance > 0,
    prePay: elem.prePay,
    debt: elem.debt,
    date: moment(elem.date).format('DD/MM/YYYY'),
    status: elem.settled ? 'Pagado' : `${isThisWeek ? 'Pendiente' : 'Vencida'}`,
  };
}

const Liquidations = () => {
  const [handlePageChange] = useOutletContext();
  const [selectedLiquidation, setSelectedLiquidation] = useState(null);
  useEffect(() => {
    handlePageChange(null, true);
  }, [selectedLiquidation]);

  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const { loading, data, error } = useQuery(GET_LIQUIDATIONS.query, {
    ...GET_LIQUIDATIONS.policies,
    variables: {
      uid,
    },
  });

  const [content, setContent] = useState([]);

  useEffect(() => {
    if (data) {
      const auxData = data.getDriverBillWeek;
      const auxContent = auxData.map((liquidation) => {
        return transformLiquidation(liquidation);
      });

      setContent(auxContent);
    }
  }, [data, loading]);

  if (loading) {
    return (
      <Box>
        <TopNav2 title="Liquidaciones" />
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Loader size={32} pointRadio="4px" />
        </Box>
      </Box>
    );
  }

  if (error) {
    return (
      <Box>
        <TopNav2 title="Liquidaciones" />
        <EmptyView />
      </Box>
    );
  }

  if (selectedLiquidation) {
    const { date, debt, prePay } = selectedLiquidation;
    return (
      <ResumeDetail
        uid={uid}
        dateParam={date}
        debtParam={debt}
        prePayParam={prePay}
        goBack={() => setSelectedLiquidation(null)}
      />
    );
  }

  return (
    <Box sx={{ overflowY: 'scroll' }}>
      <TopNav2 title="Liquidaciones" handlePageChange={() => setSelectedLiquidation(null)} />

      {content.length > 0 ? (
        <Box
          sx={{
            height: '100%',
            paddingInline: '30px',
            gap: '20px',
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '30px',
            paddingBottom: '40px',
          }}
        >
          {content.map((elem) => (
            <LiquidationsBox
              key={elem.id}
              content={elem}
              onClick={() => setSelectedLiquidation(elem)}
            />
          ))}
        </Box>
      ) : (
        <EmptyView />
      )}
    </Box>
  );
};

export default Liquidations;
