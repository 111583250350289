import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Toolbar, IconButton } from '@mui/material';
import { KeyboardArrowLeftOutlined, HelpOutline } from '@mui/icons-material';

function TopBar({ prevPage, title, home, handleExtra, tutorial, addTutorial, linkTutorial }) {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 2,
        height: '60px',
        backgroundColor: '#FFF',
        top: 0,
        left: 0,
        right: 0,
      }}
    >
      <Toolbar sx={{ zIndex: 5, display: 'flex', justifyContent: 'space-between' }}>
        {tutorial ? (
          <IconButton />
        ) : (
          <IconButton
            sx={{
              visibility: !home ? 'visible' : 'hidden',
              margin: '0px',
              padding: '0px',
              color: '#999999',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() => {
              handleExtra();
              navigate(prevPage || '/app/v2/dashboard', {
                replace: true,
              });
            }}
          >
            <KeyboardArrowLeftOutlined sx={{ margin: 0, fontSize: '1.875rem' }} />
          </IconButton>
        )}
        <Box alignItems="center" display="flex" justifyContent="center">
          <Typography
            sx={{
              color: 'black',
              fontFamily: 'Poppins-Medium',
              fontSize: '1rem',
              margin: 0,
              lineHeight: 1,
            }}
          >
            {title}
          </Typography>
        </Box>
        {addTutorial ? (
          <IconButton
            sx={{
              margin: '0px',
              padding: '0px',
              color: '#999999',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() => {
              handleExtra();
              navigate(linkTutorial, { replace: true });
            }}
          >
            <HelpOutline sx={{ margin: 0, fontSize: '1.55rem' }} />
          </IconButton>
        ) : (
          <IconButton />
        )}
      </Toolbar>
    </Box>
  );
}

TopBar.propTypes = {
  addTutorial: PropTypes.bool,
  tutorial: PropTypes.bool,
  linkTutorial: PropTypes.string,
  title: PropTypes.string.isRequired,
  prevPage: PropTypes.string,
  home: PropTypes.bool,
  handleExtra: PropTypes.func,
};

TopBar.defaultProps = {
  addTutorial: false,
  tutorial: false,
  linkTutorial: '/app/tutorial/menu',
  prevPage: '/app/v2/dashboard',
  home: false,
  handleExtra: () => {},
};

export default TopBar;
