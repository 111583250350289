import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Card, Typography } from '@tucar/pit-stop';
import { formatterCLP } from '../../../../helpers/formatters';

const SumatoryCost = ({ sections, kms, currency }) => {
  let kmsAuxCost = kms;

  const calculateKmCost = sections.map((section) => {
    const { startKm, endKm, variable } = section;
    let value = 0;
    if (kmsAuxCost > Math.abs(startKm - endKm)) {
      kmsAuxCost -= Math.abs(startKm - endKm);
      if (kmsAuxCost > 0) {
        value += Math.abs(startKm - endKm) * (variable * currency).toFixed(0);
      }
    } else if (kmsAuxCost > 0 && kmsAuxCost < Math.abs(startKm - endKm)) {
      value += kmsAuxCost * (variable * currency).toFixed(0);
      kmsAuxCost = 0;
    }
    return value;
  });

  return (
    <Card
      style={{
        padding: '23px 38px',
        background:
          'linear-gradient(308deg, rgba(255, 255, 255, 0.53) 2.36%, rgba(255, 255, 255, 0.00) 61.95%), #E3EDF7',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '11px', width: '100%' }}>
        <Typography style={{ fontSize: '14px' }} weight="light">
          Total de km recorridos: <Typography style={{ fontSize: '14px' }}>{kms} km</Typography>
        </Typography>
        <Typography style={{ fontSize: '14px' }} weight="light">
          Costo kilometraje:{' '}
          <Typography style={{ fontSize: '14px' }}>
            {formatterCLP.format(calculateKmCost.reduce((acc, sum) => acc + sum, 0))}
          </Typography>
        </Typography>
      </Box>
    </Card>
  );
};

SumatoryCost.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({})),
  kms: PropTypes.number,
  currency: PropTypes.number,
};

SumatoryCost.defaultProps = {
  sections: [],
  kms: 0,
  currency: 0,
};

export default SumatoryCost;
