import { useMemo } from 'react';

const useGoToProfileApp = () => {
  const profileUrl = useMemo(() => `${process.env.PROFILE_APP_URL}/profile`, []);

  const goToProfileApp = () => {
    const newWindow = window.open(profileUrl, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return { goToProfileApp };
};

export default useGoToProfileApp;
