import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Button, Card, Icon, Typography } from '@tucar/pit-stop';
import { azulTucar } from '../../../../themes/variants/TucarColors';
import PressIconButton from '../../../../components/buttons/PressIconButton';

const WaitingStep = ({ onNext }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        '::before': {
          content: '""',
          zIndex: 1,
          position: 'absolute',
          width: '663px',
          height: '663px',
          borderRadius: '663px',
          top: '50%',
          left: '50%',
          backdropFilter: 'blur(2px)',
          transform: 'translate(-50%, -50%)',
          background: 'linear-gradient(180deg, #EAFDFE 0%, #CDC9F1 100%)',
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          bottom: '-100%',
          zIndex: 1,
          left: '0px',
          display: 'flex',
          flexDirection: 'column',
          gap: '30px',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', paddingInline: '20px' }}>
          <Typography align="left" color={azulTucar} style={{ fontSize: '18px', fontWeight: 600 }}>
            Estado de solicitudes
          </Typography>
          <Typography
            color={azulTucar}
            align="left"
            style={{ fontSize: '14px', fontWeight: 400, lineHeight: '16px' }}
          >
            Cada vez que ingreses una solicitud, aparecerá al inicio y podrás seguir el estado.
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography color={azulTucar} style={{ fontSize: '14px', fontWeight: 500 }}>
            Paso 8/11
          </Typography>
          <Button
            size="small"
            onClick={() => {
              onNext();
              document.getElementById('section-9').scrollIntoView({ behavior: 'auto' });
            }}
          >
            Siguiente
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          zIndex: 1,
        }}
      >
        <Box sx={{ display: 'flex', gap: '15px', paddingTop: '35px' }}>
          <Card style={{ padding: '24px 16px', width: '100%' }}>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <PressIconButton>
                <Icon name="carRentalFilled" color={azulTucar} size="36px" />
              </PressIconButton>
              <Box sx={{ display: 'flex', flexDirection: 'column', paddingInline: '20px' }}>
                <Typography
                  color="#5B5D71"
                  style={{ fontSize: '16px', fontWeight: 600, lineHeight: 1.2 }}
                >
                  En lista de espera
                </Typography>
                <Typography
                  color="#5B5D71"
                  style={{
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: 1.2,
                    marginBottom: '16px',
                    marginTop: '5px',
                  }}
                >
                  Estamos asignando un un vehículo para ti.
                </Typography>
                <Button size="small" style={{ width: 'max-content', alignSelf: 'end' }}>
                  <Typography color="#5B5D71" style={{ fontSize: '14px', fontWeight: 500 }}>
                    Ver más
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

WaitingStep.propTypes = {
  onNext: PropTypes.func,
};

WaitingStep.defaultProps = {
  onNext: () => {},
};

export default WaitingStep;
