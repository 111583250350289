import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useMutation } from '@apollo/client';
import UPDATE_INFO_DRIVER from '../../../graphql/mutations/driver/updateInfoDriver';
import InfoForm from './InfoForm';
import ErrorModal from '../../../components/ui/ErrorModal';
import TopNav2 from '../../../components/ui/TopNav/newTopNav';

const OnBoardingInfo = ({ goNextStep, goBack }) => {
  const [open, setOpen] = useState(false);

  const [updateDriverInfo, { loading, data, error }] = useMutation(
    UPDATE_INFO_DRIVER.mutation,
    UPDATE_INFO_DRIVER.policies,
  );
  const executeMutation = (variables) => {
    updateDriverInfo({
      variables,
    });
  };

  useEffect(() => {
    if (!loading) {
      if (data) goNextStep();
      if (error) setOpen(true);
    }
  }, [loading]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '20px',
      }}
    >
      <ErrorModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        content="Ups, ha ocurrido un error de nuestra parte. Contacta a soporte pinchando en el chat"
        title="Error al enviar información"
        emojiSymbol="🤔"
      />

      <TopNav2 prevPage="/app/onboarding" title="" handlePageChange={goBack} />
      <InfoForm executeMutation={executeMutation} />
    </Box>
  );
};

OnBoardingInfo.propTypes = {
  goNextStep: PropTypes.func,
  goBack: PropTypes.func,
};

OnBoardingInfo.defaultProps = {
  goNextStep: () => {},
  goBack: () => {},
};

export default OnBoardingInfo;
