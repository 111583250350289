import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Typography, Button } from '@tucar/pit-stop';
import ParnerCard from '../components/partnerCard';
import { azulTucar } from '../../../../themes/variants/TucarColors';

const PartnerStep = ({ onNext }) => {
  return (
    <Box>
      <Box
        sx={{
          position: 'absolute',
          top: '0px',
          left: '0px',
          width: '100vw',
          height: '100vh',
          background: 'linear-gradient(180deg, #EAFFFE 0%, rgba(205, 201, 241, 0.80) 100%)',
          backdropFilter: 'blur(2px)',
          paddingInline: '27px',
          zIndex: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '30px 20px',
            paddingTop: '0px',
            width: 'calc(100vw - 50px)',
            gap: '10px',
          }}
        >
          <Typography
            align="center"
            color={azulTucar}
            style={{ fontSize: '18px', fontWeight: 600 }}
          >
            Conoce a tu compañero de conducción
          </Typography>
          <Typography
            color={azulTucar}
            align="center"
            style={{ fontSize: '14px', fontWeight: 400, lineHeight: '16px' }}
          >
            En la sección &rdquo;Datos de tu Compañero&ldquo;, encontrarás toda la información que
            necesitas. ¡Contacta con él fácilmente a través de WhatsApp o llamada en cualquier
            momento para coordinar!
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            paddingBottom: '30px',
            width: '100%',
          }}
        >
          <Typography color={azulTucar} style={{ fontSize: '14px', fontWeight: 500 }}>
            Paso 2/6
          </Typography>
          <Button
            size="small"
            onClick={() => {
              onNext();
              document.getElementById('section-2').scrollIntoView({ behavior: 'auto' });
            }}
          >
            Siguiente
          </Button>
        </Box>

        <ParnerCard />
      </Box>
    </Box>
  );
};

PartnerStep.propTypes = {
  onNext: PropTypes.func,
};

PartnerStep.defaultProps = {
  onNext: () => {},
};

export default PartnerStep;
