import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { BarGraph, Button, Typography } from '@tucar/pit-stop';
import { azulTucar } from '../../../../themes/variants/TucarColors';
import { currentData } from '../aux';

const GraphStep = ({ onNext }) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ position: 'relative', zIndex: 1 }}>
        <Box
          sx={{
            position: 'absolute',
            zIndex: 2,
            bottom: '-100%',
            width: '100%',
            '::before': {
              content: '""',
              zIndex: -1,
              position: 'absolute',
              top: '-100%',
              left: '-10%',
              width: '200vw',
              height: '130vh',
              borderRadius: '100vw 100vw 0px 0px',
              transform: 'translateX(-25%)',
              background: 'linear-gradient(180deg, #EAFFFE 0%, rgba(205, 201, 241, 0.80) 100%)',
              backdropFilter: 'blur(2px)',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              paddingBottom: '30px',
              paddingInline: '10%',
            }}
          >
            <Typography
              align="left"
              color={azulTucar}
              style={{ fontSize: '18px', fontWeight: 600 }}
            >
              Kilómetros recorridos
            </Typography>
            <Typography
              color={azulTucar}
              align="left"
              style={{ fontSize: '14px', fontWeight: 400, lineHeight: '16px' }}
            >
              Detalles de tus Kilómetros: Explora tu ruta día a día. Observa el rango de kilómetros
              recorridos por día y el total acumulado.
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <Typography color={azulTucar} style={{ fontSize: '14px', fontWeight: 500 }}>
              Paso 11/11
            </Typography>
            <Button
              size="small"
              onClick={() => {
                onNext();
                document.getElementById('section-end').scrollIntoView({ behavior: 'auto' });
              }}
            >
              Finalizar tutorial
            </Button>
          </Box>
        </Box>

        <Box sx={{ position: 'absolute', zIndex: 2, width: '100%' }}>
          <Box
            sx={{
              marginTop: '34px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography
              color={azulTucar}
              style={{
                fontSize: '24px',
                fontWeight: 600,
                textAlign: 'center',
              }}
            >
              192.029 KM
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingBottom: '20px',
              transform: 'translateY(-40px)',
            }}
          >
            {currentData.kmByDay.length > 0 && (
              <BarGraph
                data={currentData.kmByDay}
                step={5}
                barWidth="18px"
                gap="20px"
                total={100}
                maxWidth="max-content"
                maxHeight="250px"
                labelType="km"
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

GraphStep.propTypes = {
  onNext: PropTypes.func,
};

GraphStep.defaultProps = {
  onNext: () => {},
};

export default GraphStep;
