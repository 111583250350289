import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Button, Icon, Typography } from '@tucar/pit-stop';
import { azulTucar } from '../../../themes/variants/TucarColors';
import PressIconButton from '../../../components/buttons/PressIconButton';
import { getStepNumber, getStepTitle, getStepDescription } from '../dashboard/aux';

const NavBarTutorial = ({ step, onNext }) => {
  return (
    <>
      <Box
        sx={{
          visibility: step ? 'visible' : 'hidden',
          position: 'absolute',
          top: 'calc(100% - 330px)',
          width: '100vw',
          padding: '26px 30px',
          left: '0px',
          bottom: 0,
          borderRadius: '20px 20px 0px 0px',
          background: 'linear-gradient(180deg, #EAFFFE 0%, rgba(205, 201, 241, 0.80) 100%)',
          backdropFilter: 'blur(2px)',
          zIndex: 2,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography color={azulTucar} style={{ fontSize: '14px', fontWeight: 500 }}>
            Paso {getStepNumber(step)}/11
          </Typography>
          <Button
            size="small"
            onClick={() => {
              if (step === 'liquidations') {
                document.getElementById(`section-5`).scrollIntoView({ behavior: 'auto' });
              }
              onNext();
            }}
          >
            Siguiente
          </Button>
        </Box>
        <Box
          sx={{
            paddingTop: '20px',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            alignItems: 'center',
          }}
        >
          <Typography
            align="center"
            color={azulTucar}
            style={{ fontSize: '16px', fontWeight: 600 }}
          >
            {getStepTitle(step)}
          </Typography>
          <Typography
            align="center"
            color={azulTucar}
            style={{ fontSize: '14px', fontWeight: 500 }}
          >
            {getStepDescription(step)}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: step !== '' ? '2' : '1',
          height: '84px',
          borderRadius: '10px 10px 0px 0px',
          backgroundColor: step ? 'transparent' : '#FFF',
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}
      >
        {step === 'dashboard' ? (
          <PressIconButton size="50px">
            <Icon name="dashboardFilled" color={azulTucar} size="30px" />
          </PressIconButton>
        ) : (
          <Icon name="dashboardFilled" color={step ? 'transparent' : '#8E90A4'} size="30px" />
        )}

        {step === 'subscription' ? (
          <PressIconButton size="50px">
            <Icon name="directionsCarFilled" color={azulTucar} size="30px" />
          </PressIconButton>
        ) : (
          <Icon name="directionsCarFilled" color={step ? 'transparent' : '#8E90A4'} size="30px" />
        )}

        {step === 'gainings' ? (
          <PressIconButton size="50px">
            <Icon name="attachMoneyFilled" color={azulTucar} size="30px" />
          </PressIconButton>
        ) : (
          <Icon name="attachMoneyFilled" color={step ? 'transparent' : '#8E90A4'} size="30px" />
        )}

        {step === 'liquidations' ? (
          <PressIconButton size="50px">
            <Icon name="libraryBooksFilled" color={azulTucar} size="30px" />
          </PressIconButton>
        ) : (
          <Icon name="libraryBooksFilled" color={step ? 'transparent' : '#8E90A4'} size="30px" />
        )}

        <Icon name="supportAgentFilled" color={step ? 'transparent' : '#8E90A4'} size="30px" />
      </Box>
    </>
  );
};

export default NavBarTutorial;

NavBarTutorial.propTypes = {
  step: PropTypes.string,
  onNext: PropTypes.func,
};

NavBarTutorial.defaultProps = {
  step: '',
  onNext: () => {},
};
