import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { useOutletContext } from 'react-router-dom';
import { Loader, Typography } from '@tucar/pit-stop';
import { Box } from '@mui/material';

import { getAuthStore } from '../../../graphql/store';
import GET_GAININGS from '../../../graphql/querys/balance/getGainings';
import TopNav2 from '../../../components/ui/TopNav/newTopNav';
import EmptyView from '../../../components/extras/EmptyView';
import GraphOptions from './graphSection';
import DateSelector from './dateSelector';
import { getEndOfWeek, getStartOfWeek } from './auxFunctions';
import getUniqueKey from '../../../helpers/customKey';
import CardButton from './cardButton';
import UberTrips from './uberTrips';
import TagCards from './tagCards';
import EventCards from './eventCards';

const GainingView = ({ liquidationMode, dataDate, handlePageChangeNav }) => {
  const [handlePageChange] = useOutletContext();
  const [selectedDate, setSelectedDate] = useState(dataDate || new Date());
  const [content, setContent] = useState([]);
  const [page, setPage] = useState(null);

  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);

  const [getGainings, { loading, error, data }] = useLazyQuery(
    GET_GAININGS.query,
    GET_GAININGS.policies,
  );

  useEffect(() => {
    handlePageChange(null, true);
  }, []);

  useEffect(() => {
    getGainings({
      variables: {
        uid,
        initialDatetime: getStartOfWeek(selectedDate, false),
        finalDatetime: getEndOfWeek(selectedDate, false),
      },
    });
  }, [selectedDate]);

  useEffect(() => {
    if (data?.getDriverBillDaysLimitDates) {
      setContent(data.getDriverBillDaysLimitDates ?? []);
    }
  }, [data]);

  const dataCardIncomes = [
    {
      icon: 'localCarWashFilled',
      label: 'Ganancias por Uber',
      total: content.reduce((acc, curr) => curr.incomes.uber + acc, 0),
      onPress: () => setPage('UberTrips'),
    },
    {
      icon: 'attachMoneyFilled',
      label: 'Otros ingresos',
      total: content.reduce((acc, curr) => curr.incomes.other + acc, 0),
      onPress: () => setPage('OtherIncomeEvents'),
    },
  ];

  const dataCardExpenses = [
    {
      icon: 'localFireDepartmentFilled',
      label: 'Penalizaciones por Tucar',
      total: content.reduce((acc, curr) => curr.expenses.penaltys + acc, 0),
      onPress: () => setPage('ExchangeEvents'),
    },
    {
      icon: 'locationOnRounded',
      label: 'TAG',
      total: content.reduce((acc, curr) => curr.expenses.tag + acc, 0),
      onPress: () => setPage('Tags'),
    },
    {
      icon: 'localPoliceFilled',
      label: 'Multas de tránsito',
      total: content.reduce((acc, curr) => curr.expenses.fines + acc, 0),
      onPress: () => setPage('ControlEvents'),
    },
    {
      icon: 'modeFilled',
      label: 'Otros cargos',
      total: content.reduce((acc, curr) => curr.expenses.other + acc, 0),
      onPress: () => setPage('OtherExpenseEvents'),
    },
    {
      icon: 'carRentalFilled',
      label: 'Arriendo',
      total: content.reduce((acc, curr) => curr.expenses.rent + acc, 0),
      disabled: true,
    },
  ];

  if (loading) {
    return (
      <Box>
        <TopNav2
          handlePageChange={handlePageChangeNav}
          title="Tus Ganancias"
          prevPage={liquidationMode ? '/app/v2/liquidations' : null}
        />
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Loader size={32} pointRadio="4px" />
        </Box>
      </Box>
    );
  }

  if (error) {
    return (
      <Box>
        <TopNav2
          handlePageChange={handlePageChangeNav}
          title="Tus Ganancias"
          prevPage={liquidationMode ? '/app/v2/liquidations' : null}
        />
        <EmptyView />
      </Box>
    );
  }

  if (page === 'UberTrips') {
    return (
      <UberTrips
        liquidationMode={liquidationMode}
        selectedDate={selectedDate}
        data={content}
        setPage={setPage}
      />
    );
  }

  if (page === 'Tags') {
    return (
      <TagCards
        liquidationMode={liquidationMode}
        selectedDate={selectedDate}
        data={content}
        setPage={setPage}
      />
    );
  }

  if (page === 'ControlEvents') {
    return (
      <EventCards
        liquidationMode={liquidationMode}
        selectedDate={selectedDate}
        data={content.reduce(
          (acc, curr) => [...acc, ...curr.events.filter((event) => event.credited.ref !== uid)],
          [],
        )}
        setPage={setPage}
        eventType="Control"
      />
    );
  }

  if (page === 'OtherIncomeEvents') {
    return (
      <EventCards
        liquidationMode={liquidationMode}
        selectedDate={selectedDate}
        data={content.reduce(
          (acc, curr) => [...acc, ...curr.events.filter((event) => event.credited.ref === uid)],
          [],
        )}
        setPage={setPage}
      />
    );
  }

  if (page === 'OtherExpenseEvents') {
    return (
      <EventCards
        liquidationMode={liquidationMode}
        selectedDate={selectedDate}
        data={content.reduce(
          (acc, curr) => [
            ...acc,
            ...curr.events.filter(
              (event) =>
                event.credited.ref !== uid && !['Control', 'Exchange'].includes(event.subject),
            ),
          ],
          [],
        )}
        setPage={setPage}
      />
    );
  }

  if (page === 'ExchangeEvents') {
    return (
      <EventCards
        liquidationMode={liquidationMode}
        selectedDate={selectedDate}
        data={content.reduce(
          (acc, curr) => [...acc, ...curr.events.filter((event) => event.credited.ref !== uid)],
          [],
        )}
        setPage={setPage}
        eventType="Exchange"
      />
    );
  }

  return (
    <Box>
      <TopNav2
        handlePageChange={handlePageChangeNav}
        title="Tus Ganancias"
        prevPage={liquidationMode ? '/app/v2/liquidations' : null}
      />
      {!liquidationMode ? (
        <Box sx={{ paddingInline: '30px', marginBottom: '36px' }}>
          <DateSelector onChange={(date) => setSelectedDate(date)} selectedDate={selectedDate} />
        </Box>
      ) : (
        <Box
          sx={{
            marginBottom: '36px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            style={{
              color: '#5B5D71',
              fontWeight: 400,
              lineHeight: 1.2,
              fontSize: '14px',
            }}
          >
            Periodo
          </Typography>
          <Typography
            style={{
              color: '#5B5D71',
              fontWeight: 500,
              lineHeight: 1.2,
              fontSize: '14px',
            }}
          >
            {getStartOfWeek(selectedDate)} al {getEndOfWeek(selectedDate)}
          </Typography>
        </Box>
      )}
      <GraphOptions billDays={content} selectedDate={selectedDate} />

      <Box sx={{ paddingInline: '20px', marginTop: '40px' }}>
        <Typography style={{ fontSize: '16px', fontWeight: 600, color: '#2D2F39' }}>
          Desgloce de ganancias
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '15px',
            marginTop: '20px',
            marginBottom: '35px',
            justifyContent: 'center',
          }}
        >
          {dataCardIncomes.map((item) => (
            <CardButton
              key={getUniqueKey()}
              icon={item.icon}
              label={item.label}
              total={item.total}
              onClick={item.onPress}
              disabled={item.disabled}
            />
          ))}
        </Box>
      </Box>

      <Box sx={{ paddingInline: '20px', marginTop: '40px' }}>
        <Typography style={{ fontSize: '16px', fontWeight: 600, color: '#2D2F39' }}>
          Desgloce de cargos
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '15px',
            marginTop: '20px',
            marginBottom: '35px',
            justifyContent: 'center',
          }}
        >
          {dataCardExpenses.map((item) => (
            <CardButton
              key={getUniqueKey()}
              icon={item.icon}
              label={item.label}
              total={item.total}
              onClick={item.onPress}
              disabled={item.disabled}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

GainingView.propTypes = {
  dataDate: PropTypes.instanceOf(Date),
  liquidationMode: PropTypes.bool,
  handlePageChangeNav: PropTypes.func,
};

GainingView.defaultProps = {
  dataDate: new Date(),
  liquidationMode: false,
  handlePageChangeNav: () => {},
};

export default GainingView;
