import React from 'react';
import PropTypes from 'prop-types';

import { Box, BoxFlex } from '@tucar/pit-stop';
import DocsForm from './DocsForm';
import TopNav2 from '../../../components/ui/TopNav/newTopNav';
import HeaderDocs from './HeaderDocs';

const OnBoardingDocuments = ({ goBack, goNextStep }) => {
  return (
    <Box style={{ height: '100vh', width: '100vw', background: '#F0F0F3' }}>
      <TopNav2 prevPage="/app/onboarding" title="" handlePageChange={goBack} />

      <BoxFlex
        flexDirection="column"
        gap="20px"
        style={{
          alignItems: 'center',
          paddingInline: '30px',
          width: '100%',
          paddingBottom: '50px',
        }}
      >
        <HeaderDocs />
        <DocsForm goNext={goNextStep} />
      </BoxFlex>
    </Box>
  );
};

OnBoardingDocuments.propTypes = {
  goBack: PropTypes.func,
  goNextStep: PropTypes.func,
};

OnBoardingDocuments.defaultProps = {
  goBack: () => {},
  goNextStep: () => {},
};

export default OnBoardingDocuments;
