import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Tabs } from '@tucar/pit-stop';
import { Box } from '@mui/material';

import TopNav2 from '../../../components/ui/TopNav/newTopNav';
import CarInfo from './Car';
import PlanInfo from './Plan';

moment.locale('es');

const NewSubscriptionPlan = ({ onBoarding }) => {
  const [content, setContent] = useState('Arriendo');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
        minWidth: !onBoarding ? '100vw' : '100%',
      }}
    >
      {!onBoarding && <TopNav2 title="Suscripción" />}
      {!onBoarding && (
        <Tabs
          style={{ paddingInline: '30px', marginBottom: '30px' }}
          tabs={[{ value: 'Arriendo' }, { value: 'Vehículo' }]}
          currentTab={content}
          onChange={(e) => setContent(e.currentTarget.value)}
        />
      )}
      <Box sx={{ paddingInline: '10px' }}>
        {content === 'Arriendo' ? <PlanInfo onBoarding={onBoarding} /> : <CarInfo />}
      </Box>
    </Box>
  );
};

NewSubscriptionPlan.propTypes = {
  onBoarding: PropTypes.bool,
};

NewSubscriptionPlan.defaultProps = {
  onBoarding: false,
};
export default NewSubscriptionPlan;
