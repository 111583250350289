import React from 'react';
import { Icon, Button } from '@tucar/pit-stop';
import { Box } from '@mui/material';

import { azulTucar } from '../../../../themes/variants/TucarColors';

const CreatePetitionButton = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '22px' }}>
      <Button
        fullWidth
        contentStyle={{
          justifyContent: 'center',
          display: 'grid',
          gridTemplateColumns: 'min-content 1fr',
        }}
        size="large"
        startAdornment={<Icon name="publishedWithChangesFilled" size="24px" color={azulTucar} />}
        endAdornment={
          <Box sx={{ transform: 'rotate(180deg)' }}>
            <Icon name="leftArrowSpecial" size="16px" color="#A8AABA" />
          </Box>
        }
      >
        Solicitar cambio
      </Button>
      <Button variant="ghost">Ver solicitudes anteriores</Button>
    </Box>
  );
};

export default CreatePetitionButton;
