import React from 'react';
import { Box } from '@mui/material';

import { useAuthParams } from '../../../hooks';
import { revokeToken } from '../../../api/auth';
import { getRefreshToken, getAuthStore, logout } from '../../../graphql/store';
// import { useSalesforceContext } from '../../../contexts/SalesforceContext';
import SubmitButton from '../../SubmitButton';

const LogOutSubmit = () => {
  const { getLogoutUrl } = useAuthParams();
  // const { openSalesforceChat } = useSalesforceContext();

  const locallyLogout = () => {
    logout();
    window.location.href = getLogoutUrl();
  };

  const onClickLogoutHandler = async () => {
    const refreshToken = getRefreshToken();
    const { accessToken } = getAuthStore();
    await revokeToken(accessToken, { onError: locallyLogout });
    await revokeToken(refreshToken, {
      onError: locallyLogout,
      onSuccess: locallyLogout,
    });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
      {/* <SubmitButton text="Soporte" handler={openSalesforceChat} /> */}
      <SubmitButton text="Soporte" handler={() => window.Intercom('show')} />
      <SubmitButton text="Salir" handler={onClickLogoutHandler} />
    </Box>
  );
};

export default LogOutSubmit;
