import { makeRequest } from '../../helpers/requestHelpers';

const getTokenMetadata = async (refreshToken, { onError, onSuccess }) => {
  const params = { token: refreshToken };
  return makeRequest(`${process.env.ACCOUNT_SERVICE_URL}/api/v1/resources/token-metadata`, {
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    requestType: 'GET',
    onSuccess,
    onError,
  });
};

export default getTokenMetadata;
