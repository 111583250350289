import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Collapse, Typography, IconButton, Stack } from '@mui/material';
import { Card, TextField, Button } from '@tucar/pit-stop';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import bankImage from '../../../../helpers/bankImage';
import ConfirmModal from '../../../../components/ui/ConfirmModal';

function selectColor(open, principal) {
  if (principal) {
    return '#0057B81A';
  }
  if (open) return '#FFF';
  return '#F3F3F3';
}

const AccountingInfo = ({ bank, principal, selectionFunction, refetch, removeFunction }) => {
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  const maskCreditCard = (card) => {
    if (card.length < 9) {
      return `****${card.substr(card.length - 4)}`;
    }
    const maskCard = card.substr(card.length - 4);
    return `**** **** **** ${maskCard}`;
  };

  const groupByN = (n, arr) => {
    const result = [];
    for (let i = 0; i < arr.length; i += n) result.push(arr.slice(i, i + n));
    return result;
  };

  const creditCard = (card) => {
    if (card.length < 9) return card;
    const listCard = groupByN(4, card);
    return listCard.join(' ');
  };

  return (
    <Box sx={{ paddingInline: '20px' }}>
      <ConfirmModal
        open={confirmOpen}
        setOpen={setConfirmOpen}
        title="¿Seguro quieres eliminar esta cuenta bancaria?"
        content="Esta acción no podrá ser revertida una vez realizada"
        handleConfirm={async () => {
          // eslint-disable-next-line no-underscore-dangle
          await removeFunction({ uid: bank._id });
          await refetch();
        }}
      />
      <Card
        style={{
          backgroundColor: selectColor(open, principal),
          transition: 'background-color 0.8s',
        }}
      >
        <Stack
          sx={{ width: '100%' }}
          onClick={handleClick}
          paddingLeft="7px"
          paddingRight="4px"
          borderRadius={open ? '15px 15px 0px 0px' : '15px'}
          direction="row"
          alignItems="center"
          height="45px"
          justifyContent="space-between"
        >
          <Box paddingLeft="10px" display="flex" alignItems="center" gap="5%" width="50%">
            <img src={bankImage(bank.bank)} alt="banco" width="25px" />
            <Typography
              sx={{
                color: '#333333',
                fontSize: '14px',
                fontFamily: 'Poppins-Regular',
                lineHeight: 1,
              }}
            >
              {bank.bank}
            </Typography>
          </Box>

          <Box>
            <Typography
              sx={{
                color: '#333333',
                fontSize: '10px',
                fontFamily: 'Poppins-Regular',
                lineHeight: 1,
              }}
            >
              {open ? creditCard(bank.account) : maskCreditCard(bank.account)}
            </Typography>
          </Box>

          {open ? (
            <IconButton onClick={handleClick}>
              <KeyboardArrowUpOutlinedIcon fontSize="small" sx={{ color: '#333333' }} />
            </IconButton>
          ) : (
            <IconButton onClick={handleClick}>
              <KeyboardArrowDownOutlinedIcon fontSize="small" sx={{ color: '#333333' }} />
            </IconButton>
          )}
        </Stack>

        <Collapse
          sx={{
            borderRadius: open ? '0px 0px 7px 7px' : '7px',
            width: '100%',
            paddingInline: '20px',
            paddingBottom: '20px',
          }}
          in={open}
          timeout="auto"
          unmountOnExit
        >
          <Box sx={{ marginTop: '10px', display: 'flex', flexDirection: 'column', gap: '15px' }}>
            <TextField
              onlyLabel
              readOnly
              fullWidth
              size="large"
              label="Titular"
              value={bank.name}
            />
            <TextField onlyLabel readOnly fullWidth size="large" label="Rut" value={bank.rut} />
            <TextField
              onlyLabel
              readOnly
              fullWidth
              size="large"
              label="Tipo de cuenta"
              value={bank.accountType}
            />
            <TextField
              onlyLabel
              readOnly
              fullWidth
              size="large"
              label="Correo electrónico"
              value={bank.email}
            />

            <Box sx={{ marginTop: '10px', display: 'flex', gap: '15px' }}>
              <Button
                fullWidth
                contentStyle={{ justifyContent: 'center', lineHeight: 1 }}
                disabled={principal}
                onClick={async () => {
                  // eslint-disable-next-line no-underscore-dangle
                  await selectionFunction({ uid: bank._id });
                  await refetch();
                }}
              >
                Cuenta principal
              </Button>
              {!principal ? (
                <Button
                  variant="ghost"
                  contentStyle={{ justifyContent: 'center' }}
                  fullWidth
                  onClick={() => {
                    setConfirmOpen(true);
                  }}
                >
                  Eliminar
                </Button>
              ) : null}
            </Box>
          </Box>
        </Collapse>
      </Card>
    </Box>
  );
};

AccountingInfo.propTypes = {
  bank: PropTypes.shape({
    _id: PropTypes.string,
    email: PropTypes.string,
    accountType: PropTypes.string,
    rut: PropTypes.string,
    name: PropTypes.string,
    account: PropTypes.string,
    bank: PropTypes.string,
  }).isRequired,
  principal: PropTypes.bool,
  selectionFunction: PropTypes.func,
  refetch: PropTypes.func,
  removeFunction: PropTypes.func,
};

AccountingInfo.defaultProps = {
  principal: false,
  selectionFunction: () => {},
  refetch: () => {},
  removeFunction: () => {},
};

export default AccountingInfo;
