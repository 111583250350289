import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, BoxFlex } from '@tucar/pit-stop';
import PropTypes from 'prop-types';

import { useQuery, useReactiveVar } from '@apollo/client';
import TopNav2 from '../../../components/ui/TopNav/newTopNav';
import { getAuthStore } from '../../../graphql/store';
import GET_UBER_INFORMATION from '../../../graphql/querys/driver/getUberInformation';
import ErrorModal from '../../../components/ui/ErrorModal';
import LoadingProgress from '../../../components/ui/LoadingProgress';
import UberProGoals from './UberProGoals';
import EmptyView from '../../../components/extras/EmptyView';

const UberBond = ({ goBack }) => {
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState();
  const { loading, data, error } = useQuery(GET_UBER_INFORMATION.query, {
    variables: { uid },
    ...GET_UBER_INFORMATION.policies,
  });

  useEffect(() => {
    if (!loading) {
      if (data && data.getUber) {
        setValues(data.getUber);
      }
      if (error) {
        setOpen(true);
      }
    }
  }, [loading]);

  if (loading) {
    return (
      <Box
        style={{
          height: '100vh',
          width: '100vw',
          background: '#F0F0F3',
        }}
      >
        <TopNav2 prevPage="/app/onboarding" title="" handlePageChange={goBack} />
        <LoadingProgress />
      </Box>
    );
  }

  return (
    <Box
      style={{
        height: '100vh',
        width: '100vw',
        background: '#F0F0F3',
      }}
    >
      <ErrorModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        content="Ha ocurrido un error recuperando tu información de Uber, por favor intenta de nuevo más tarde o contacta con soporte para revisar el caso."
        title="Error al recibir información"
      />

      <TopNav2 prevPage="/app/onboarding" title="" handlePageChange={goBack} />
      {!values ? (
        <EmptyView />
      ) : (
        <Box style={{ paddingInline: '30px' }}>
          <Typography
            align="center"
            style={{
              textAlign: 'center',
              fontSize: '22px',
              fontWeight: 600,
              letterSpacing: 0.56,
              color: '#0057B8',
              paddingBottom: '15px',
            }}
          >
            Tu cuenta de Uber a sido vinculada con éxito
          </Typography>

          <Card
            style={{
              padding: '19px 20px',
              flexDirection: 'column',
              gap: '20px',
              alignItems: 'center',
              marginTop: '20px',
              marginBottom: '20px',
            }}
          >
            <Typography style={{ color: '#0057B8', fontWeight: 600 }} align="center">
              Información de tu cuenta de Uber
            </Typography>

            <BoxFlex flexDirection="column" style={{ alignSelf: 'baseline' }}>
              <Typography style={{ color: '#5B5D71', fontWeight: 600 }}>Nombre</Typography>
              <Typography style={{ color: '#5B5D71', fontWeight: 500 }}>
                {values?.firstname} {values?.lastname}
              </Typography>
            </BoxFlex>

            <BoxFlex flexDirection="column" style={{ alignSelf: 'baseline' }}>
              <Typography style={{ color: '#5B5D71', fontWeight: 600 }}>Correo</Typography>
              <Typography style={{ color: '#5B5D71', fontWeight: 500 }}>{values?.email}</Typography>
            </BoxFlex>

            <BoxFlex flexDirection="column" style={{ alignSelf: 'baseline' }}>
              <Typography style={{ color: '#5B5D71', fontWeight: 600 }}>Teléfono</Typography>
              <Typography style={{ color: '#5B5D71', fontWeight: 500 }}>
                {values?.code} {values?.phone[0]} {values?.phone.slice(1)}
              </Typography>
            </BoxFlex>
          </Card>

          <Card>
            <UberProGoals />
          </Card>
        </Box>
      )}
    </Box>
  );
};

export default UberBond;

UberBond.propTypes = {
  goBack: PropTypes.func,
};

UberBond.defaultProps = {
  goBack: () => {},
};
