import { gql } from '@apollo/client';

const GET_CONFIG_PLANS_V2 = {
  query: gql`
    query GetConfigPlans {
      getConfigPlans {
        _id
        name
        includes
        exclusive
        active
        electric
        shifts {
          _id
          available
          type
          startTime
          endTime
        }
      }
    }
  `,
};

export default GET_CONFIG_PLANS_V2;
