import { useMemo } from 'react';

import { buildRequestParams } from '../helpers/requestHelpers';
import baseParams from '../api/auth/config';
import { getRandomState } from '../graphql/store';

const useAuthParams = () => {
  const params = useMemo(
    () => ({
      ...baseParams,
      response_type: 'code',
      scope: 'driver',
      tenancy: process.env.MODE !== 'production' ? 'development' : 'production',
      state: getRandomState(),
    }),
    [],
  );

  const authUrl = useMemo(
    () => `${process.env.ACCOUNT_APP_URL}?${buildRequestParams(params)}`,
    [params, process.env.ACCOUNT_APP_URL],
  );

  const getLogoutUrl = () =>
    `${process.env.ACCOUNT_APP_URL}/logout/?${buildRequestParams({
      ...params,
      state: getRandomState(),
    })}`;

  return { authUrl, getLogoutUrl };
};

export default useAuthParams;
