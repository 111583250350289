import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import getUniqueKey from '../../../helpers/customKey';

const gap = 8;

const defaultStyle = {
  zIndex: 2,
  opacity: 1,
  display: 'flex',
  borderRadius: '8px',
  position: 'absolute',
  background: '#fff',
  alignItems: 'center',
  transition: 'left 0.5s ease',
  justifyContent: 'center',
};

const AnimatedStep = ({
  index,
  cantSteps,
  primaryColor,
  widthActive,
  widthDisable,
  heightActive,
  heightDisable,
  borderRadius,
}) => {
  return (
    <Box
      style={{
        zIndex: 3,
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          height: `${heightActive}px`,
          display: 'flex',
          overflow: 'hidden',
          alignSelf: 'center',
          justifyItems: 'center',
          position: 'relative',
          width: `${widthActive + widthDisable * (cantSteps - 1) + gap * cantSteps}px`,
        }}
      >
        <Box
          sx={{
            ...defaultStyle,
            borderRadius,
            height: `${heightActive}px`,
            background: primaryColor,
            width: `${widthActive}px`,
            left: `${index * (widthDisable + gap)}px`,
          }}
        />

        <Box
          sx={{
            ...defaultStyle,
            borderRadius,
            background: primaryColor,
            height: `${heightDisable}px`,
            zIndex: 1,
            opacity: 0.5,
            width: `${widthDisable}px`,
            left: index > 0 ? '0px' : `${widthActive + gap}px`,
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        />

        {Array.from({ length: cantSteps }).map((_, i) => {
          if (i <= 1) return null;
          return (
            <Box
              key={getUniqueKey()}
              sx={{
                ...defaultStyle,
                borderRadius,
                background: primaryColor,
                height: `${heightDisable}px`,
                zIndex: 1,
                opacity: 0.5,
                width: `${widthDisable}px`,
                top: '50%',
                transform: 'translateY(-50%)',
                left:
                  index >= i
                    ? `${(widthDisable + gap) * (i - 1)}px`
                    : `${widthActive + i * gap + (i - 1) * widthDisable}px`,
              }}
            />
          );
        })}
      </Box>
    </Box>
  );
};

AnimatedStep.propTypes = {
  index: PropTypes.number,
  cantSteps: PropTypes.number,
  primaryColor: PropTypes.string,
  widthActive: PropTypes.number,
  widthDisable: PropTypes.number,
  heightActive: PropTypes.number,
  heightDisable: PropTypes.number,
  borderRadius: PropTypes.string,
};

AnimatedStep.defaultProps = {
  index: 0,
  cantSteps: 3,
  primaryColor: '#FFF',
  widthActive: 34,
  widthDisable: 16,
  heightActive: 8,
  heightDisable: 8,
  borderRadius: '8px',
};

export default AnimatedStep;
