import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import axios from 'axios';
import { useReactiveVar, useQuery, NetworkStatus } from '@apollo/client';

import { Box, BoxFlex, Button, Loader } from '@tucar/pit-stop';
import { getAuthStore } from '../../../graphql/store';
import GET_DOCUMENTS from '../../../graphql/querys/docs/getDocuments';
import UploadFileButton from '../../../components/UploadFileButton';
import translateDocsName from '../../../helpers/translateDocsName';
import ErrorModal from '../../../components/ui/ErrorModal';

const DocsForm = ({ goNext }) => {
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    cedulaIdentidadFront: false,
    cedulaIdentidadBack: false,
    licenciaConducirFront: false,
    licenciaConducirBack: false,
    certificadoAntecedentes: false,
    hojaVida: false,
  });

  const handleChange = (name, event) => {
    setValues((currentValues) => ({
      ...currentValues,
      [name]: event,
    }));
  };

  const {
    data: docsData,
    loading,
    networkStatus,
  } = useQuery(GET_DOCUMENTS.query, { variables: { uid }, ...GET_DOCUMENTS.policies });

  useEffect(() => {
    if (!loading && docsData?.getDocument) {
      const auxData = docsData.getDocument.files.map((elem, ident) => {
        const auxObj = { ...elem };
        // eslint-disable-next-line no-param-reassign
        auxObj.fileType = 'application/pdf, image/*';
        auxObj.loadingRefetch = false;
        auxObj.id = ident;
        return auxObj;
      });
      auxData.map((elem) => handleChange(elem.name, elem));
    }
  }, [docsData, loading, networkStatus]);

  // const handleUpload = (name, file) => {
  //   const dataUpload = new FormData();
  //   dataUpload.append('files', file, `${name}.${file.name.split('.').pop()}`);
  //   dataUpload.append('id', uid);
  //   console.log('dataUpload', dataUpload.get('files'));
  //   const config = {
  //     method: 'post',
  //     url: `${process.env.DOCUMENT_GATEWAY_URL}/api/v1/cl/document/attach`,
  //     headers: {
  //       'Content-Type': 'multipart/form-data',
  //       Authorization:
  //         process.env.mode === 'development'
  //           ? null
  //           : `Apikey ${process.env.DOCUMENT_GATEWAY_API_KEY}`,
  //     },
  //     data: dataUpload,
  //   };
  //   axios(config)
  //     .then((response) => {
  //       const dataAux = {
  //         ...response.data[0],
  //         fileType: 'application/pdf, image/*',
  //         loadingRefetch: false,
  //       };
  //       handleChange(dataAux.name, dataAux);
  //     })
  //     .catch((e) => {
  //       console.log('error', e);
  //       console.log('config', config);
  //       setOpen(true);
  //     });
  //   return false;
  // };

  if (loading || networkStatus === NetworkStatus.refetch) {
    return (
      <BoxFlex style={{ paddingTop: '30px', justifyContent: 'center' }}>
        <Loader size={32} pointRadio="4px" />
      </BoxFlex>
    );
  }

  return (
    <BoxFlex flexDirection="column" gap="16px" sx={{ width: '100%' }}>
      <ErrorModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        content="Ups, ha ocurrido un error de nuestra parte. Contacta a soporte pinchando en el chat"
        title="Error al subir archivo"
      />
      {Object.entries(values).map(([name, file]) => {
        return (
          <UploadFileButton
            key={name}
            url={file?.url}
            status={file?.status}
            label={translateDocsName(name)}
            fileType={file?.fileType}
            fileName={name}
            handler={handleChange}
            openError={() => setOpen(true)}
          />
        );
      })}
      <Box style={{ paddingTop: '20px', alignSelf: 'center' }}>
        <Button onClick={goNext} disabled={Object.values(values).some((value) => !value)}>
          Siguiente
        </Button>
      </Box>
    </BoxFlex>
  );
};

export default DocsForm;

DocsForm.propTypes = {
  goNext: PropTypes.func,
};

DocsForm.defaultProps = {
  goNext: () => {},
};
