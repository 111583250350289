import { gql } from '@apollo/client';
import { setVerifiedList } from '../../store';

const GET_VERIFIED_LIST = {
  query: gql`
    query GetVerifiedList($uid: ID!) {
      getDriver(uid: $uid) {
        verifiedList {
          app
          auth
          bank
          docs
          guarantee
          info
          meeting
          plan
          tc
        }
      }
    }
  `,
  policies: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore-all',
    onCompleted: (response) => {
      const { verifiedList } = response.getDriver;
      setVerifiedList(verifiedList);
    },
  },
};

export default GET_VERIFIED_LIST;
