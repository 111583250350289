import React from 'react';
import PropTypes from 'prop-types';
import { BoxFlex, Typography } from '@tucar/pit-stop';

import CardShape from '../planSelection/PlanDisplay/CardShape';
import useResizeObserver from '../planSelection/PlanDisplay/resizeObserver';

const YouTubeEmbed = ({ url, focusCard, position, title }) => {
  const [containerRef] = useResizeObserver();

  let videoId = '';

  if (url.includes('youtube.com/watch?v=')) {
    videoId = url.split('v=')[1]?.split('&')[0];
  } else if (url.includes('youtu.be/')) {
    videoId = url.split('youtu.be/')[1]?.split('?')[0];
  } else {
    return <p>URL de YouTube no válida</p>;
  }

  const embedUrl = `https://www.youtube.com/embed/${videoId}`;

  return (
    <BoxFlex flexDirection="column" alignItems="center">
      <Typography
        align="center"
        style={{
          width: '80%',
          fontSize: '14px',
          fontWeight: 600,
          letterSpacing: 0.28,
          color: '#1F89FF',
          lineHeight: 1,
          textAlign: 'center',
          paddingBottom: '20px',
        }}
      >
        {title}
      </Typography>

      <CardShape noClipPath focusCard={focusCard} position={position}>
        <BoxFlex
          ref={containerRef}
          flexDirection="column"
          style={{
            gap: '20px',
            alignItems: 'center',
          }}
        >
          <iframe
            src={embedUrl}
            width="200px"
            height="300px"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
            style={{
              top: 0,
              left: 0,
              marginInline: '30px',
            }}
          />
        </BoxFlex>
      </CardShape>
    </BoxFlex>
  );
};

YouTubeEmbed.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  focusCard: PropTypes.bool,
  position: PropTypes.string,
};

YouTubeEmbed.defaultProps = {
  url: '',
  title: '',
  focusCard: false,
  position: 'center',
};

export default YouTubeEmbed;
