import React from 'react';
import PropTypes from 'prop-types';
import { BoxFlex, Button, Typography } from '@tucar/pit-stop';
import { CheckCircle } from '@mui/icons-material';
import getUniqueKey from '../../../../helpers/customKey';
import CardShape from './CardShape';
import useResizeObserver from './resizeObserver';

const CardWrapper = ({ incomeList, title, focusCard, position, darkMode, disabled, onClick }) => {
  const [containerRef, { width: containerWidth, height: containerHeight }] = useResizeObserver();

  return (
    <CardShape focusCard={focusCard} position={position}>
      <BoxFlex
        ref={containerRef}
        flexDirection="column"
        style={{
          background: darkMode ? '#000E1F' : '#FFF',
          padding: '20px',
          gap: '20px',
          alignItems: 'center',
          clipPath: `path("\
          M ${containerWidth * 0.15} 10\
          A 10 10 0 0 1 ${containerWidth * 0.15 + 10} 0\
          L ${containerWidth - containerWidth * 0.15 - 10} 0\
          A 10 10 0 0 1 ${containerWidth - containerWidth * 0.15} 10\
          L ${containerWidth - containerWidth * 0.15} 25\
          L ${containerWidth - 10} 25\
          A 10 10 0 0 1 ${containerWidth} 35\
          L ${containerWidth} ${containerHeight - 10}\
          A 10 10 0 0 1 ${containerWidth - 10} ${containerHeight}\
          L 10 ${containerHeight}\
          A 10 10 0 0 1 0 ${containerHeight - 10}\
          L 0 35\
          A 10 10 0 0 1 10 25\
          L ${containerWidth * 0.15} 25\
          Z")`,
        }}
      >
        <Typography
          align="center"
          style={{
            width: '80%',
            fontSize: '14px',
            fontWeight: 600,
            letterSpacing: 0.28,
            color: '#1F89FF',
            lineHeight: 1,
            textAlign: 'center',
          }}
        >
          {title}
        </Typography>

        <BoxFlex flexDirection="column" gap="10px" style={{ alignSelf: 'start' }}>
          {incomeList.map((income) => (
            <BoxFlex key={getUniqueKey()} gap="5px" alignItems="start">
              <CheckCircle sx={{ fontSize: '9px', color: '#95D31C', marginTop: '2px' }} />
              <Typography
                style={{
                  color: darkMode ? '#FFF' : '#616161',
                  fontSize: '13px',
                  lineHeight: 1,
                  letterSpacing: 0.26,
                  fontWeight: 400,
                  textOverflow: 'clip',
                }}
              >
                {income}
              </Typography>
            </BoxFlex>
          ))}
        </BoxFlex>
        {focusCard && (
          <Button
            onClick={onClick}
            disabled={disabled}
            size="small"
            style={{ marginTop: '30px', lineHeight: 1.2 }}
          >
            {disabled ? 'Deshabilitado' : '¡Lo quiero!'}
          </Button>
        )}
      </BoxFlex>
    </CardShape>
  );
};

export default CardWrapper;

CardWrapper.propTypes = {
  incomeList: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  focusCard: PropTypes.bool,
  position: PropTypes.string,
  darkMode: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

CardWrapper.defaultProps = {
  incomeList: [],
  title: '',
  focusCard: false,
  position: 'center',
  darkMode: false,
  disabled: false,
  onClick: () => {},
};
