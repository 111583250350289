import { gql } from '@apollo/client';

const EDIT_PREFERENCE = {
  mutation: gql`
    mutation EditDriverPlan($driverId: ID!, $planId: ID!, $shiftId: ID, $exchangeAddress: String) {
      editPreference(
        driverId: $driverId
        planId: $planId
        shiftId: $shiftId
        exchangeAddress: $exchangeAddress
      ) {
        _id
      }
    }
  `,
  policies: {
    errorPolicy: 'ignore',
  },
};

export default EDIT_PREFERENCE;
