import { gql } from '@apollo/client';

const CREATE_PREFERENCE = {
  mutation: gql`
    mutation CreateDriverPlan(
      $driverId: ID!
      $planId: ID!
      $shiftId: ID!
      $exchangeAddress: String
    ) {
      createPreference(
        driverId: $driverId
        planId: $planId
        shiftId: $shiftId
        exchangeAddress: $exchangeAddress
      ) {
        _id
      }
    }
  `,
  policies: {
    errorPolicy: 'ignore',
  },
};

export default CREATE_PREFERENCE;
