import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { Box } from '@mui/material';
import { TextField, Dropdown, Button, Icon, TucarTheme } from '@tucar/pit-stop';
import { rutValidator } from '../../../../helpers/rutValidador';

import {
  getAuthStore,
  getDriverStore,
  getGlobalStore,
  setLoading,
} from '../../../../graphql/store';
import country from '../../../../helpers/bd/chile';
import LoadingProgress from '../../../../components/ui/LoadingProgress';

const translateParking = (value) => {
  switch (value) {
    case '0':
      return 'No';
    case '1':
      return 'Si';
    default:
      return '';
  }
};

const translateGender = (value) => {
  switch (value) {
    case 'female':
      return 'Femenino';
    case 'male':
      return 'Masculino';
    default:
      return 'Prefiero no decir';
  }
};

export default function PersonalForm({ executeMutation }) {
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const { info } = useReactiveVar(getDriverStore);
  const { loading } = useReactiveVar(getGlobalStore);
  const [loadingButton, setLoadingButton] = useState(false);
  const [values, setValues] = useState({
    ...info,
    uid,
  });
  const [edit, setEdit] = useState(false);
  const isEmptyObject = (obj) => {
    const aux = { ...obj };
    if (Object.keys(aux).includes('adrapt')) {
      delete aux.adrapt;
      delete aux.emergencyPhone;
      delete aux.secondPhone;
    }
    return Object.values(aux).every((value) => value !== '');
  };

  const regions = country.map((elem) => elem.name);
  const provincias = !values.region
    ? []
    : country.filter((region) => region.name === values.region)[0]?.provincias || [];

  const communes = !values.city
    ? []
    : provincias.filter((citys) => citys.name === values.city)[0]?.comunas || [];

  useEffect(() => {
    if (uid) {
      setValues({ ...values, uid });
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [uid]);

  const handleChange = (name, event) => {
    if (name === 'region') {
      setValues((currentValues) => ({
        ...currentValues,
        city: '',
        commune: '',
        [name]: event,
      }));
    } else if (name === 'city') {
      setValues((currentValues) => ({
        ...currentValues,
        commune: '',
        [name]: event,
      }));
    } else {
      setValues((currentValues) => ({
        ...currentValues,
        [name]: event,
      }));
    }
  };

  const handleClick = () => {
    setEdit(false);
    setLoadingButton(false);
    executeMutation(values);
  };

  const resetInfo = async () => {
    setLoadingButton(true);
    await setValues({
      ...info,
      uid,
    });
    setEdit(false);
    setLoadingButton(false);
  };

  if (loading || loadingButton) {
    return <LoadingProgress />;
  }

  return (
    <Box
      fullwidth
      sx={{ paddingInline: '30px', display: 'flex', flexDirection: 'column', gap: '20px' }}
    >
      <TextField
        readOnly
        onlyLabel
        mask="00.000.000-#"
        maskValue
        definition={{ '#': /[0-9Kk]/ }}
        required
        fullWidth
        label="RUT"
        value={values.rut}
        helperType="error"
        helperText={!rutValidator(values.rut) && 'Rut inválido'}
        onChange={(value) => handleChange('rut', value.toUpperCase())}
      />

      <TextField
        readOnly
        onlyLabel
        required
        type="date"
        fullWidth
        label="Fecha de nacimiento"
        value={values.birth}
      />

      <TextField
        readOnly
        onlyLabel
        mask="+56 (0) 0000 0000"
        required
        fullWidth
        type="tel"
        label="Teléfono"
        value={values.phone}
        onChange={(e) => handleChange('phone', e)}
      />

      <TextField
        readOnly={!edit}
        onlyLabel={!edit}
        mask="+56 (0) 0000 0000"
        fullWidth
        type="tel"
        label="Teléfono secundario"
        value={values.secondPhone}
        onChange={(e) => handleChange('secondPhone', e)}
        endAdornment={edit && <Icon name="modeOutlined" color={TucarTheme.colors.blue500} />}
      />

      <TextField
        readOnly={!edit}
        onlyLabel={!edit}
        mask="+56 (0) 0000 0000"
        fullWidth
        type="tel"
        label="Teléfono de emergencia"
        value={values.emergencyPhone}
        onChange={(e) => handleChange('emergencyPhone', e)}
        endAdornment={edit && <Icon name="modeOutlined" color={TucarTheme.colors.blue500} />}
      />

      <Dropdown
        fullWidth
        disabled={!edit}
        label="Género"
        value={values.gender}
        options={['female', 'male', 'other']}
        placeholder="Selecciona"
        getOptionLabel={translateGender}
        onChange={(e) => handleChange('gender', e)}
      />

      <Dropdown
        fullWidth
        disabled={!edit}
        label="Estacionamiento privado (no calle)"
        value={values.parking}
        options={['1', '0']}
        placeholder="Selecciona"
        getOptionLabel={translateParking}
        onChange={(e) => handleChange('parking', e)}
      />

      <Dropdown
        fullWidth
        disabled={!edit}
        label="Región"
        value={values.region}
        options={regions}
        placeholder="Selecciona"
        onChange={(e) => handleChange('region', e)}
      />

      <Dropdown
        fullWidth
        disabled={!edit}
        label="Provincia"
        value={values.city}
        options={provincias.map((elem) => elem.name)}
        placeholder="Selecciona"
        onChange={(e) => handleChange('city', e)}
      />

      <Dropdown
        fullWidth
        disabled={!edit}
        label="Comuna"
        value={values.commune}
        options={communes.map((elem) => elem.name)}
        placeholder="Selecciona"
        onChange={(e) => handleChange('commune', e)}
      />

      <TextField
        required
        readOnly={!edit}
        fullWidth
        onlyLabel
        label="Dirección"
        value={values.address}
        onChange={(e) => handleChange('address', e)}
        endAdornment={edit && <Icon name="modeOutlined" color={TucarTheme.colors.blue500} />}
      />

      <TextField
        required
        readOnly={!edit}
        fullWidth
        onlyLabel
        label="Número"
        value={values.adrnumber}
        onChange={(e) => handleChange('adrnumber', e)}
        endAdornment={edit && <Icon name="modeOutlined" color={TucarTheme.colors.blue500} />}
      />

      <TextField
        readOnly={!edit}
        fullWidth
        onlyLabel
        label="Casa/dpto/oficina"
        value={values.adrapt}
        onChange={(e) => handleChange('adrapt', e)}
        endAdornment={edit && <Icon name="modeOutlined" color={TucarTheme.colors.blue500} />}
      />

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {edit ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Button
              fullwidth
              contentStyle={{ width: 'max-content' }}
              disabled={loadingButton || !isEmptyObject(values) || !rutValidator(values.rut)}
              size="large"
              onClick={handleClick}
            >
              Guardar cambios
            </Button>
            <Button fullWidth variant="ghost" size="large" onClick={resetInfo}>
              <Box sx={{ textAlign: 'center', width: '100%' }}>Cancelar</Box>
            </Button>
          </Box>
        ) : (
          <Button
            contentStyle={{ width: 'max-content' }}
            size="large"
            onClick={() => setEdit(true)}
          >
            <Box sx={{ textAlign: 'center' }}>Editar datos personales</Box>
          </Button>
        )}
      </Box>
    </Box>
  );
}

PersonalForm.propTypes = {
  executeMutation: PropTypes.func.isRequired,
};
