import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import useResizeObserver from './resizeObserver';

const CardShape = ({ focusCard, position, children, noClipPath }) => {
  const [containerRef, { width: containerWidth, height: containerHeight }] = useResizeObserver();

  return (
    <Box
      ref={containerRef}
      sx={{
        display: 'inline-block',
        width: 'fit-content',
        maxWidth: '80%',
        overflow: 'hidden',
        ...(focusCard
          ? {}
          : {
              background: '#FFF',
              backgroundFilter: 'blur(10px)',
              padding: '1px',
              width: '100%',
              maxWidth: '100%',
              position: 'absolute',
              top: '50%',
              transform:
                position === 'left'
                  ? 'translate(-80%, -50%) scale(0.6)'
                  : 'translate(80%, -50%) scale(0.6)',
              ...(position === 'left' ? { left: '0%' } : { right: '0%' }),
            }),
        clipPath: noClipPath
          ? ``
          : `path("\
      M ${containerWidth * 0.15} 10\
      A 10 10 0 0 1 ${containerWidth * 0.15 + 10} 0\
      L ${containerWidth - containerWidth * 0.15 - 10} 0\
      A 10 10 0 0 1 ${containerWidth - containerWidth * 0.15} 10\
      L ${containerWidth - containerWidth * 0.15} 25\
      L ${containerWidth - 10} 25\
      A 10 10 0 0 1 ${containerWidth} 35\
      L ${containerWidth} ${containerHeight - 10}\
      A 10 10 0 0 1 ${containerWidth - 10} ${containerHeight}\
      L 10 ${containerHeight}\
      A 10 10 0 0 1 0 ${containerHeight - 10}\
      L 0 35\
      A 10 10 0 0 1 10 25\
      L ${containerWidth * 0.15} 25\
      Z")`,
      }}
    >
      {children}
    </Box>
  );
};

export default CardShape;

CardShape.propTypes = {
  focusCard: PropTypes.bool,
  position: PropTypes.string,
  children: PropTypes.node,
  noClipPath: PropTypes.bool,
};

CardShape.defaultProps = {
  focusCard: false,
  position: 'center',
  children: null,
  noClipPath: false,
};
