import React from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { Box, Typography, BoxFlex } from '@tucar/pit-stop';
import { getDriverStore } from '../../../graphql/store';

import TopNav2 from '../../../components/ui/TopNav/newTopNav';
import BaseButton from './baseButton';

const OnBoardingGeneral = ({ setCurrentTab, goBack }) => {
  const { verifiedList } = useReactiveVar(getDriverStore);

  return (
    <Box
      style={{
        height: '100vh',
        width: '100vw',
      }}
    >
      <TopNav2 prevPage="/app/onboarding" title="" handlePageChange={goBack} />
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '0px 25px 95px 25px',
        }}
      >
        <Typography
          style={{
            fontSize: '28px',
            fontWeight: 600,
            letterSpacing: 0.56,
            color: '#0057B8',
            paddingBottom: '15px',
          }}
        >
          Completa tu perfil
        </Typography>
        <Typography
          style={{
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: 1.5,
            letterSpacing: 0.28,
            color: '#0057B8',
          }}
        >
          Una vez completados tus datos, podrás acceder fácilmente a todos los beneficios y
          servicios que ofrecemos.
        </Typography>

        <BoxFlex flexDirection="column" gap="40px" style={{ padding: '50px 10px' }}>
          <BaseButton
            completed={verifiedList?.info}
            title="Datos personales"
            goToFunction={() => setCurrentTab('basicInfo-2')}
          />
          <BaseButton
            completed={verifiedList?.docs}
            title="Documentos del conductor"
            goToFunction={() => setCurrentTab('documents')}
          />
          <BaseButton
            completed={verifiedList?.bank}
            title="Cuenta bancaria"
            goToFunction={() => setCurrentTab('bankAccount')}
          />
          <BaseButton
            completed={verifiedList?.plan}
            title="Plan de arriendo"
            goToFunction={() => setCurrentTab('plan')}
          />
          <BaseButton
            completed={verifiedList?.app}
            title="Vincular cuenta Uber"
            goToFunction={() => setCurrentTab('app')}
          />
        </BoxFlex>
      </Box>
    </Box>
  );
};

OnBoardingGeneral.propTypes = {
  setCurrentTab: PropTypes.func,
  goBack: PropTypes.func,
};

OnBoardingGeneral.defaultProps = {
  setCurrentTab: () => {},
  goBack: () => {},
};

export default OnBoardingGeneral;
