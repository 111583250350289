import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useReactiveVar, useQuery } from '@apollo/client';
import { useOutletContext } from 'react-router-dom';
import { Card, TucarTheme, Modal, Loader } from '@tucar/pit-stop';

import { getAuthStore, getDriverStore } from '../../../../graphql/store';
import GET_DRIVER_PLAN from '../../../../graphql/querys/plan/getPlan';
import getPlanDisponibilityHours from '../../../../helpers/getPlanDisponibilityHours';
import SectionKms from './SectionsKms';
import SumatoryCost from './SumatoryCost';
import PlanAdds from './PlanAdds';
import PetitionButton from './petitionButton';
import EmptyView from '../../../../components/extras/EmptyView';

moment.locale('es');

const weekDays = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];

const PlanInfo = ({ onBoarding }) => {
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);

  const {
    category,
    info: { country },
    status,
  } = useReactiveVar(getDriverStore);
  const { uberPro } = category || { uberPro: 'Blue' };
  const [handlePageChange] = useOutletContext();
  const [openModal, setOpenModal] = useState(false);
  const [dataPlan, setDataPlan] = useState({
    duration: 0,
    currentDay: 0,
    basePrice: 0,
    electric: false,
    exclusive: false,
    name: '',
    uberDiscount: 0,
    currency: 0,
    includes: [],
    sections: [],
  });
  useEffect(() => {
    handlePageChange(null, !onBoarding);
  }, []);

  const { data, loading, error } = useQuery(GET_DRIVER_PLAN.query, {
    ...GET_DRIVER_PLAN.policies,
    variables: {
      driverId: uid,
      country,
    },
    context: {
      ...(['churned', 'revoked', 'rejected', 'on boarding'].includes(status?.status) && {
        ignorePattern: {
          pattern: ['Message: notificationApi error: No notifications found for driver'],
        },
      }),
    },
  });

  useEffect(() => {
    if (data && data.getDriverPlanV2 && data.getConfigAccounting) {
      const configAccounting = data.getConfigAccounting;
      const { plan, selectedShifts, driver } = data.getDriverPlanV2;
      const billDays = data.getDriverBillDaysParsedByWeek.instances;

      const today = (moment().day() + 6) % 7;

      const auxDataFormat = {
        registrationDate: moment(driver.createdAt).format('DD/MM/YYYY'),
        currentDay: today,
        electric: plan.electric,
        includes: plan.includes,
        sections: plan.sections,
        totalKm:
          billDays.length > 0 ? billDays[0].billDays.reduce((acc, curr) => acc + curr.km, 0) : 0,
        name: selectedShifts[today].name,
        basePrice: selectedShifts[today].basePrice,
        currency: configAccounting.currency.current,
        exclusive: plan.exclusive || plan.shifts.length === 1,
        uberDiscount: (uberPro && configAccounting?.uberDiscount[uberPro]) || 0,
        startTime: selectedShifts[today].startTime,
        endTime: selectedShifts[today].endTime,
        duration: getPlanDisponibilityHours(
          selectedShifts[today].startTime,
          selectedShifts[today].endTime,
        ),
      };

      setDataPlan(auxDataFormat);
    }
  }, [data, loading]);

  if (loading) {
    return (
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Loader size={32} pointRadio="4px" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box>
        <EmptyView />
      </Box>
    );
  }

  return (
    <>
      <Modal
        open={openModal}
        title="¿Cómo calcular el costo del plan?"
        variant="informative"
        onConfirm={() => setOpenModal(false)}
      >
        Todos nuestros planes se componen por un costo base, más un costo variable, el cual va a
        depender de la cantidad de kilómetros que recorras en la semana. Podrás acceder a diferentes
        descuentos en tu plan de arriendo, según tu categoría en Uber Pro. Todos nuestros valores
        están en UF.
      </Modal>

      <Box
        sx={{
          marginBottom: '50px',
          paddingInline: !onBoarding ? '20px' : 0,
          display: 'flex',
          flexDirection: 'column',
          gap: '30px',
        }}
      >
        {!onBoarding && <PetitionButton />}

        <Box
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            alignItems: 'center',
            gap: '20px',
            minHeight: '112px',
          }}
        >
          <Card
            style={{
              minHeight: '112px',
              height: 'max-content',
              padding: '20px 16px',
            }}
          >
            <Box sx={{ width: '100%', overflow: 'hidden' }}>
              <Typography
                sx={{
                  fontFamily: 'Poppins-Medium',
                  fontSize: '14px',
                  color: TucarTheme.colors.gray700,
                  lineHeight: 1.2,
                }}
              >
                Tipo de plan
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Poppins-SemiBold',
                  fontSize: '16px',
                  color: TucarTheme.colors.green500,
                  textOverflow: 'ellipsis',
                  lineHeight: 1.2,
                }}
              >
                {dataPlan.name}
              </Typography>
            </Box>
          </Card>

          <Card style={{ padding: '13px', alignItems: 'unset', height: '100%' }}>
            <Typography
              sx={{
                fontSize: '14px',
                fontFamily: 'Poppins-Regular',
                textAlign: 'start',
                color: TucarTheme.colors.gray700,
              }}
            >
              {dataPlan.duration} horas
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                fontFamily: 'Poppins-Medium',
                color: TucarTheme.colors.blue500,
                lineHeight: '1',
              }}
            >
              {dataPlan.startTime} a {dataPlan.endTime} hrs.
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                fontFamily: 'Poppins-Regular',
                textAlign: 'end',
                lineHeight: '1',
                color: TucarTheme.colors.gray700,
              }}
            >
              Hoy {weekDays[dataPlan.currentDay]}
            </Typography>
          </Card>
        </Box>

        {!onBoarding && (
          <>
            <SectionKms
              discount={dataPlan.uberDiscount}
              sections={dataPlan?.sections}
              kms={dataPlan?.totalKm}
              currency={dataPlan.currency}
            />

            <SumatoryCost
              sections={dataPlan?.sections}
              kms={dataPlan?.totalKm}
              currency={dataPlan.currency}
            />
          </>
        )}
        <PlanAdds includeArray={dataPlan?.includes} />
      </Box>
    </>
  );
};

PlanInfo.propTypes = {
  onBoarding: PropTypes.bool,
};

PlanInfo.defaultProps = {
  onBoarding: false,
};

export default PlanInfo;
