import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import PersonalInfo from './PersonalInfo';
import AccountingInfo from './Accounting';
import Documents from './Documents';
import ErrorModal from '../../../components/ui/ErrorModal';
import GeneralProfile from './General';
import TopNav2 from '../../../components/ui/TopNav/newTopNav';

const NewProfile = () => {
  const [handlePageChange] = useOutletContext();
  const [currentTab, setCurrentTab] = useState('general');

  const tabTitle = (tab) => {
    switch (tab) {
      case 'personal':
        return 'Datos generales';
      case 'documents':
        return 'Documentos';
      case 'accounting':
        return 'Cuentas bancarias';
      default:
        return (
          <Typography fontFamily="Poppins-SemiBold" color="#FFF">
            Perfil
          </Typography>
        );
    }
  };

  useEffect(() => {
    handlePageChange(null, true);
  }, []);

  const [open, setOpen] = useState(false);
  const [content, setContent] = useState('');

  const tabComponent = {
    personal: <PersonalInfo />,
    documents: <Documents setMessageError={setContent} setOpenError={setOpen} />,
    accounting: <AccountingInfo />,
    general: (
      <GeneralProfile
        setCurrentTab={setCurrentTab}
        setMessageError={setContent}
        setOpenError={setOpen}
      />
    ),
  };

  return (
    <>
      <Box
        sx={{
          background:
            currentTab === 'general' ? 'linear-gradient(#0496FF 80px, #F0F0F3 300px)' : '#F0F0F3',
          display: 'flex',
          flexDirection: 'column',
          paddingBottom: '50px',
        }}
      >
        <TopNav2
          title={tabTitle(currentTab)}
          prevPage={currentTab === 'general' ? '/app/v2/dashboard' : '/app/v2/profile'}
          handlePageChange={() => {
            if (currentTab !== 'general') {
              setCurrentTab('general');
            }
          }}
        />
        {tabComponent[currentTab]}
      </Box>
      <ErrorModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        content={content}
        title="Error al subir imagen"
      />
    </>
  );
};

export default NewProfile;
