import { gql } from '@apollo/client';

const GET_PREFERENCES = {
  query: gql`
    query GetPreference($entityId: ID!) {
      getPreference(entityId: $entityId) {
        exchangeAddress {
          address
          latitude
          longitude
        }
      }
    }
  `,
  policies: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore-all',
  },
};

export default GET_PREFERENCES;
