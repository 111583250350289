import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Typography } from '@tucar/pit-stop';
import TopNav2 from '../../../components/ui/TopNav/newTopNav';

const HeaderSource = ({ goBack }) => {
  return (
    <>
      <TopNav2 prevPage="/app/onboarding" title="" handlePageChange={goBack} />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '20px',
          paddingInline: '30px',
          paddingTop: '20px',
        }}
      >
        <Typography
          style={{
            color: '#0057B8',
            fontSize: '28px',
            fontWeight: 600,
            letterSpacing: '0.56',
            lineHeight: 1,
          }}
        >
          Antes de continuar
        </Typography>
        <Typography
          style={{
            color: '#0057B8',
            fontSize: '14px',
            fontWeight: 500,
            letterSpacing: '0.28',
            textAlign: 'center',
            paddingInline: '20px',
          }}
        >
          Nos gustaría saber cómo te enteraste de nosotros ;)
        </Typography>
      </Box>
    </>
  );
};

HeaderSource.propTypes = {
  goBack: PropTypes.func,
};

HeaderSource.defaultProps = {
  goBack: () => {},
};

export default HeaderSource;
