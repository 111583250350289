import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useLazyQuery, useReactiveVar } from '@apollo/client';

import { getAuthStore, isAuthenticated } from '../graphql/store';
import LoadingScreen from './LoadingScreen';
import GET_INIT_DRIVER from '../graphql/querys/driver/getInitDriver';
import checkOnboarding from '../helpers/onBoard';
import ErrorScreen from './ErrorScreen';
import { send } from '../helpers/sw/connection';
import ConnectionError from './extras/ConnectionError';

const AuthGuard = () => {
  const [loadScreen, setLoadScreen] = useState(true);
  const [offline, setOffline] = useState(true);
  const navigate = useNavigate();
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);

  const [getInitDriver, { loading, error, data }] = useLazyQuery(GET_INIT_DRIVER.query, {
    ...GET_INIT_DRIVER.policies,
  });

  useEffect(() => {
    if (!window.navigator.onLine) {
      setOffline(true);
    } else {
      setOffline(false);
    }
  }, [window.navigator.onLine]);

  useEffect(() => {
    if (uid) {
      getInitDriver({ variables: { uid } });
      if ('serviceWorker' in navigator) {
        // eslint-disable-next-line no-console
        send(uid).catch((e) => console.log(e));
      }
    }
  }, []);

  useEffect(() => {
    if (!loading && data?.getDriver) {
      const { docs, plan, verifiedList } = data.getDriver || {};
      const newDocs = docs.files;
      const newDocsDict = {};
      for (let i = 0; i < newDocs.length; i += 1) {
        const { name } = newDocs[i];
        newDocsDict[name] = newDocs[i];
      }
      const planExclusive = plan?.plan?.shifts.length === 1;
      const noLineal = true;

      const { onBoarding } = checkOnboarding(verifiedList, newDocsDict, planExclusive, noLineal);

      if (!isAuthenticated()) {
        navigate('/', { replace: true });
        setLoadScreen(false);
      } else if (onBoarding && data?.getDriver?.status?.status === 'on boarding') {
        navigate('/app/onboarding', { replace: true });
        setLoadScreen(false);
      } else {
        navigate('/app/v2/dashboard', { replace: true });
        setLoadScreen(false);
      }
    }
  }, [loading]);

  useEffect(() => {
    if (!isAuthenticated()) navigate('/', { replace: true });
  }, [isAuthenticated(), navigate]);

  if (offline) return <ConnectionError />;

  if (error || (data && !data.getDriver)) return <ErrorScreen />;

  if (loadScreen || loading) return <LoadingScreen content="...Autenticando..." />;

  return <Outlet />;
};

export default AuthGuard;
