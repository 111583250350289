import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import TermsAndConditionModal from '../../components/info/termsAndCondition';
import OnBoardingDocuments from './docs';
import OnBoardingInfo from './info';
import SourceStep from './source';
import OnBoardingPlan from './planSelection';
import OnBoardingGeneral from './general';
import OnBoardingApps from './apps';
import { getAuthStore, getDriverStore, getRefreshToken, logout } from '../../graphql/store';
import { revokeToken } from '../../api/auth';
import OnBoardingBankAccount from './bankAccount';
import OnBoardingResume from './resume';
import { useAuthParams } from '../../hooks';

const OnBoarding = () => {
  const { getLogoutUrl } = useAuthParams();

  const locallyLogout = () => {
    logout();
    window.location.href = getLogoutUrl();
  };

  const onClickLogoutHandler = async () => {
    const refreshToken = getRefreshToken();
    const { accessToken } = getAuthStore();
    await revokeToken(accessToken, { onError: locallyLogout });
    await revokeToken(refreshToken, {
      onError: locallyLogout,
      onSuccess: locallyLogout,
    });
  };
  const [handlePageChange] = useOutletContext();
  const { verifiedList } = useReactiveVar(getDriverStore);

  const [step, setStep] = useState('basicInfo');
  const [content, setContent] = useState(<OnBoardingInfo />);

  useEffect(() => {
    handlePageChange();
    if (verifiedList?.info) {
      setStep('resume');
    }
  }, []);

  useEffect(() => {
    if (step === 'resume') {
      setContent(
        <OnBoardingResume goNextStep={() => setStep('general')} goBack={onClickLogoutHandler} />,
      );
    }
    if (step === 'general') {
      setContent(<OnBoardingGeneral setCurrentTab={setStep} goBack={() => setStep('resume')} />);
    }
    if (step === 'basicInfo') {
      setContent(
        <OnBoardingInfo
          goNextStep={() => setStep('source')}
          goBack={() => logout({ hardLogout: true })}
        />,
      );
    }
    if (step === 'source') {
      setContent(
        <SourceStep goBack={() => setStep('basicInfo')} goNextStep={() => setStep('resume')} />,
      );
    }
    if (step === 'basicInfo-2') {
      setContent(
        <OnBoardingInfo goNextStep={() => setStep('general')} goBack={() => setStep('general')} />,
      );
    }
    if (step === 'documents') {
      setContent(
        <OnBoardingDocuments
          goBack={() => setStep('general')}
          goNextStep={() => setStep('general')}
        />,
      );
    }
    if (step === 'plan') {
      setContent(
        <OnBoardingPlan goBack={() => setStep('general')} goNextStep={() => setStep('general')} />,
      );
    }
    if (step === 'app') {
      setContent(<OnBoardingApps goBack={() => setStep('general')} />);
    }
    if (step === 'bankAccount') {
      setContent(<OnBoardingBankAccount goBack={() => setStep('general')} />);
    }
  }, [step]);

  return (
    <Box>
      <TermsAndConditionModal />
      {content}
    </Box>
  );
};

export default OnBoarding;
