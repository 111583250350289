import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Typography } from '@tucar/pit-stop';
import TopNav2 from '../../../../components/ui/TopNav/newTopNav';
import { getEndOfWeek, getStartOfWeek } from '../auxFunctions';
import UberCard from '../../../../components/v2/UberCard';
import getUniqueKey from '../../../../helpers/customKey';
import EmptyView from '../../../../components/extras/EmptyView';

const UberTrips = ({ liquidationMode, selectedDate, data, setPage }) => {
  const uberList = data.reduce((acc, curr) => [...acc, ...curr.uber], []);

  if (uberList.length === 0) {
    return (
      <Box>
        <TopNav2
          title="Viajes en Uber"
          prevPage={liquidationMode ? '/app/v2/liquidations' : '/app/v2/balance'}
          handlePageChange={() => setPage(null)}
        />
        <EmptyView />
      </Box>
    );
  }

  return (
    <Box>
      <TopNav2
        title="Viajes en Uber"
        prevPage={liquidationMode ? '/app/v2/liquidations' : '/app/v2/balance'}
        handlePageChange={() => {
          setPage(null);
        }}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography
          style={{
            color: '#5B5D71',
            fontWeight: 400,
            lineHeight: 1.2,
            fontSize: '14px',
          }}
        >
          Periodo
        </Typography>
        <Typography
          style={{
            color: '#5B5D71',
            fontWeight: 500,
            lineHeight: 1.2,
            fontSize: '14px',
          }}
        >
          {getStartOfWeek(selectedDate)} al {getEndOfWeek(selectedDate)}
        </Typography>
      </Box>

      <Box
        sx={{
          paddingTop: '40px',
          paddingBottom: '40px',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          paddingInline: '30px',
        }}
      >
        {uberList.map((uberTrip) => (
          <UberCard key={getUniqueKey()} uberTrip={uberTrip} />
        ))}
      </Box>
    </Box>
  );
};

UberTrips.propTypes = {
  selectedDate: PropTypes.instanceOf(Date),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  setPage: PropTypes.func,
  liquidationMode: PropTypes.bool,
};

UberTrips.defaultProps = {
  selectedDate: new Date(),
  data: [],
  setPage: () => {},
  liquidationMode: false,
};

export default UberTrips;
