import React from 'react';
import PropTypes from 'prop-types';
import { Typography, BoxFlex } from '@tucar/pit-stop';

const HeaderPlan = ({ mode, title, description }) => {
  const color = mode === 'light' ? '#0057B8' : '#FFFFFF';
  return (
    <BoxFlex flexDirection="column" gap="20px" style={{ alignItems: 'center' }}>
      <Typography
        style={{
          color,
          fontSize: '28px',
          fontWeight: 600,
          letterSpacing: '0.56',
          lineHeight: 1,
        }}
      >
        {title}
      </Typography>
      {description && (
        <Typography
          style={{
            color,
            fontSize: '14px',
            fontWeight: 500,
            letterSpacing: '0.28',
            textAlign: 'center',
            paddingInline: '20px',
          }}
        >
          {description}
        </Typography>
      )}
    </BoxFlex>
  );
};

HeaderPlan.propTypes = {
  mode: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};

HeaderPlan.defaultProps = {
  mode: 'light',
  title: '',
  description: '',
};

export default HeaderPlan;
