import { useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';

import { buildRequestParams, getAppUrl } from '../helpers/requestHelpers';
import baseParams from '../api/auth/config';
import { getAuthStore } from '../graphql/store';

const useAuthParams = () => {
  const {
    data: { uid },
    accessToken,
  } = useReactiveVar(getAuthStore);
  const params = useMemo(
    () => ({
      client_id: baseParams.client_id,
      tenancy: process.env.MODE !== 'production' ? 'development' : 'production',
      user_id: uid,
      redirect_uri: `${getAppUrl()}/#/app/onboarding`,
      token: accessToken,
    }),
    [uid],
  );

  const uberUrl = useMemo(
    () => `${process.env.ACCOUNT_APP_URL}/uber-bond?${buildRequestParams(params)}`,
    [params, process.env.ACCOUNT_APP_URL],
  );

  return { uberUrl };
};

export default useAuthParams;
