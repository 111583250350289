import React, { useEffect } from 'react';
import { TucarTheme, Typography, Button } from '@tucar/pit-stop';
import { Box } from '@mui/material';
import { useOutletContext, useNavigate } from 'react-router-dom';
import TucarLogo from '../../../../public/images/logotipo.svg';
import GoalsImg from '../../../../public/images/bubbleIconsPng/goals.png';

const backgroundStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  flex: 1,
  paddingInline: '20px',
  minHeight: '100vh',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    height: '520px',
    width: '520px',
    background: TucarTheme.colors.blue500,
    borderRadius: '520px',
    top: '-244px',
    left: '83px',
    opacity: '0.3',
    filter: 'blur(100px)',
    zIndex: '0',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    height: '520px',
    width: '520px',
    background: TucarTheme.colors.blue100,
    borderRadius: '520px',
    top: '-42px',
    left: '-245px',
    opacity: '0.2',
    filter: 'blur(100px)',
    zIndex: '0',
  },
};

const WelcomePage = () => {
  const [handlePageChange] = useOutletContext();
  const navigate = useNavigate();

  useEffect(() => {
    handlePageChange();
  }, []);

  return (
    <Box sx={backgroundStyle}>
      <Box
        sx={{
          position: 'absolute',
          top: '40px',
          left: '20px',
          height: '22px',
          width: '76px',
          zIndex: 2,
        }}
      >
        <img src={TucarLogo} alt="logo" />
      </Box>

      <Box
        sx={{
          zIndex: 3,
          marginTop: '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img src={GoalsImg} alt="goals-welcome" width="90%" height="100%" />
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '19px' }}>
        <Typography
          align="center"
          variant="h1"
          style={{ fontWeight: 600, lineHeight: 1.2, fontSize: '36px' }}
        >
          Alcanza tus objetivos
        </Typography>
        <Typography
          align="center"
          variant="p"
          weight="medium"
          style={{ lineHeight: 1.2, color: TucarTheme.colors.gray700, fontSize: '14px' }}
        >
          Arrienda un auto y genera ganancias usando aplicaciones. Comienza aquí 👇🏻
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center' }}>
        <Button
          size="large"
          width="max-content"
          onClick={() => navigate('/signin', { replace: true })}
          contentStyle={{
            fontSize: '16px',
            fontWeight: 500,
          }}
        >
          Iniciar sesión
        </Button>
        <Button size="large" variant="ghost" onClick={() => navigate('/signup', { replace: true })}>
          Registrarse
        </Button>
      </Box>
    </Box>
  );
};

export default WelcomePage;
