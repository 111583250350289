import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Typography, Card } from '@tucar/pit-stop';
import getUniqueKey from '../../../../../helpers/customKey';
import { azulTucar } from '../../../../../themes/variants/TucarColors';
import PressIconButton from '../../../../../components/buttons/PressIconButton';
import EventGetTextBorder from './EventGetTextBorder';

const EventCustomColorCard = ({ color, title, content, icon }) => {
  return (
    <Card
      style={{
        position: 'relative',
        alignItems: 'unset',
        overflow: 'hidden',
        height: 'fit-content',
      }}
    >
      <Box
        style={{
          content: '',
          height: '100%',
          width: '12px',
          borderRadius: '12px 0 0 12px',
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: color,
        }}
      />

      <Box sx={{ padding: '20px 28px 20px 30px', display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            display: 'flex',
            gap: '20px',
            alignItems: 'center',
            marginBottom: '20px',
            justifyContent: 'start',
          }}
        >
          <PressIconButton size="40px">{icon}</PressIconButton>

          <Typography
            style={{
              color: azulTucar,
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: 1.2,
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box>
          {content.map((elem, index) => (
            <EventGetTextBorder
              key={getUniqueKey()}
              color={color}
              last={index === content.length - 1 || typeof elem.url === 'string'}
              title={elem.title}
              content={elem.msg}
              url={elem.url ?? null}
            />
          ))}
        </Box>
      </Box>
    </Card>
  );
};

EventCustomColorCard.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.arrayOf(PropTypes.shape({})),
  icon: PropTypes.element,
};

EventCustomColorCard.defaultProps = {
  color: '',
  title: '',
  content: [],
  icon: null,
};

export default EventCustomColorCard;
