import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

const SwipeWrapper = ({ threshold, onSwipeRight, onSwipeLeft, children }) => {
  const [startX, setStartX] = useState(null);
  const handleStart = useCallback((clientX) => setStartX(clientX), []);
  const handleMove = useCallback(
    (clientX) => {
      if (startX === null) return;
      const distance = clientX - startX;
      const activationThreshold = threshold;
      if (distance >= activationThreshold) {
        onSwipeLeft();
        setStartX(null);
      } else if (distance <= -activationThreshold) {
        onSwipeRight();
        setStartX(null);
      }
    },
    [startX, threshold, onSwipeRight, onSwipeLeft],
  );
  const handleEnd = useCallback(() => setStartX(null), []);

  const handleMouseDown = (e) => handleStart(e.clientX);
  const handleMouseMove = (e) => handleMove(e.clientX);
  const handleMouseUp = () => handleEnd();
  const handleTouchStart = (e) => handleStart(e.touches[0].clientX);
  const handleTouchMove = (e) => handleMove(e.touches[0].clientX);
  const handleTouchEnd = () => handleEnd();

  return (
    <div
      role="button"
      tabIndex={0}
      style={{
        width: '100%',
        position: 'relative',
        cursor: 'pointer',
      }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {children}
    </div>
  );
};

export default SwipeWrapper;

SwipeWrapper.propTypes = {
  onSwipeLeft: PropTypes.func,
  onSwipeRight: PropTypes.func,
  children: PropTypes.node,
  threshold: PropTypes.number,
};

SwipeWrapper.defaultProps = {
  onSwipeLeft: () => {},
  onSwipeRight: () => {},
  children: null,
  threshold: 100,
};
