import { styled } from '@mui/material/styles';
import { Autocomplete, TextField } from '@mui/material';

export const StyledSelector = styled(Autocomplete)(() => ({
  '& lu': {
    background: 'red !important',
  },
  '& .MuiAutocomplete-Option': {
    fontFamily: 'Poppins-Regular',
    fontSize: '14px',
    color: 'red',
  },
}));

export const AutocompleteInput = styled(TextField)({
  '& label': {
    fontFamily: 'Poppins-Regular',
    fontSize: '14px',
  },
  '& label.Mui-focused': {
    color: '#FFF',
  },
  '& label.Mui-focused.Mui-error ': {
    color: '#d32f2f',
  },
  '& fieldset': {
    border: '1px solid #fff !important',
  },
  '& input:valid + fieldset': {
    border: '1px solid #fff',
  },
  '&:hover fieldset': {
    borderColor: '#fff',
    borderWidth: 1,
  },
  '& .MuiInputBase-input': {
    fontSize: '14px',
    fontFamily: 'Poppins-Medium',
    fontWeight: 500,
    color: '#0057B8',
    paddingInline: '0px',
  },
  '& .MuiFormHelperText-root': {
    borderColor: '#d32f2f',
  },
  '& .MuiOutlinedInput-root': {
    fontFamily: 'Poppins-Regular',
    height: '50px',
    paddingInline: '20px !important',
    borderRadius: '4px',
    background: '#e3edf7',
    borderColor: '#fff',
    boxShadow:
      '4px 4px 20px 0px rgba(111,140,176,0.41),-6px -6px 20px 0px #fff,2px 2px 4px 0px rgba(114,142,171,0.1)',
    '&:hover fieldset': {
      boxShadow: '4px 4px 14px 0px #D9D9D9 inset,-4px -4px 9px 0px rgba(255,255,255,0.88) inset',
    },
    '&.Mui-error:hover fieldset': {
      borderColor: '#d32f2f',
    },
    '&.Mui-focused fieldset': {
      boxShadow: '4px 4px 14px 0px #D9D9D9 inset,-4px -4px 9px 0px rgba(255,255,255,0.88) inset',
    },
    '&.Mui-error :hover fieldset': {
      borderColor: '#d32f2f',
    },
    '&.Mui-focused.Mui-error fieldset': {
      borderColor: '#d32f2f',
    },
    '& .MuiAutocomplete-input': {
      padding: '7.5px 0px !important',
    },
  },
});
