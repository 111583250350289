import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { request } from 'graphql-request';
import useSWR from 'swr';
import { useReactiveVar } from '@apollo/client';

import { Button, Loader, Typography } from '@tucar/pit-stop';
import { azulTucar } from '../../../themes/variants/TucarColors';
import Emoji from '../../../components/extras/emojis';
import GET_BLOG_ENTRIES from '../../../graphql/querys/getBlogEntries';
import { getPlanStore } from '../../../graphql/store';
import womanDriver from '../../../../public/images/woman-driver.jpeg';

const specialCardStyle = {
  padding: '16px 8px',
  borderRadius: '12px',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  minWidth: 'max-content',
  boxShadow:
    '4px 4px 20px 0px rgba(111, 140, 176, 0.41), -6px -6px 20px 0px #FFF, 2px 2px 4px 0px rgba(114, 142, 171, 0.10)',
  '::before': {
    content: '""',
    position: 'absolute',
    background: '#E8F0F8',
    top: '2px',
    left: '2px',
    bottom: '2px',
    right: '2px',
    borderRadius: '8px',
    zIndex: 0,
  },
  '::after': {
    content: '""',
    position: 'absolute',
    background: 'linear-gradient(180deg,#FFF,#FFF,#E6EAF0)',
    borderRadius: '10px',
    top: '0px',
    left: '0px',
    bottom: '0px',
    right: '0px',
    zIndex: -1,
  },
};

const fetcher = (query) => request(process.env.NEXT_PUBLIC_TUCAR_BLOG, query);

const FinishStep = () => {
  const navigate = useNavigate();
  const { plan } = useReactiveVar(getPlanStore);
  const [postContent, setPostContent] = useState([]);
  const { data, loading } = useSWR(GET_BLOG_ENTRIES.query, fetcher);

  useEffect(() => {
    if (data?.posts) {
      const auxContent = data.posts.reverse().map((post) => {
        return {
          id: post.id,
          title: post.title,
          date: moment(post.datePublished, 'YYYY-MM-DD').format('DD [de] MMMM YYYY'),
          img: post.coverPhoto.url,
          slug: post.slug,
        };
      });
      setPostContent(auxContent);
    }
  }, [data]);

  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: 3,
        top: 0,
        left: 0,
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backdropFilter: 'blur(2px)',
        background:
          'linear-gradient(180deg, rgba(234, 255, 254, 0.90) 0%, rgba(205, 201, 241, 0.90) 100%)',
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography
              color={azulTucar}
              style={{ lineHeight: 1.2, fontSize: '36px', fontWeight: 500 }}
            >
              Todo listo <Emoji symbol=" 👍🏻" label="emoji" />
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              paddingInline: '40px',
              alignItems: 'center',
            }}
          >
            <Typography
              align="center"
              color="#5B5D71"
              style={{ fontSize: '14px', fontWeight: 500, lineHeight: 1.2 }}
            >
              ¡Felicidades! Has completado el tutorial. ¡Ahora estás listo para explorar y sacar el
              máximo provecho de todas nuestras funcionalidades!
            </Typography>
            <Typography color={azulTucar} style={{ fontSize: '14px', fontWeight: 500 }}>
              ¡Bienvenido a bordo! <Emoji symbol="🚙" label="emoji" />
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              align="center"
              color={azulTucar}
              style={{ fontSize: '16px', fontWeight: 600, alignSelf: 'center' }}
            >
              ¿Quieres saber más?
            </Typography>

            <Box
              sx={{
                display: 'flex',
                padding: '20px 20px 10px 20px',
                width: '100vw',
                overflowX: 'scroll',
                gap: '20px',
              }}
            >
              {postContent.map((blog) => (
                <Box key={blog.id} sx={specialCardStyle}>
                  <Box sx={{ display: 'flex', gap: '12px', zIndex: 1 }}>
                    <img
                      src={blog.img}
                      alt="imagen"
                      style={{
                        width: '95px',
                        height: '95px',
                        borderRadius: '12px',
                        objectFit: 'cover',
                      }}
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '160px' }}>
                      <Typography
                        color={azulTucar}
                        style={{
                          lineHeight: 1,
                          fontWeight: 500,
                          fontSize: '13px',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {blog.title}
                      </Typography>
                      <Typography
                        color="#71758E"
                        style={{ lineHeight: 1, fontWeight: 400, fontSize: '13px' }}
                      >
                        {blog.date}
                      </Typography>
                      <Box sx={{ flex: 1 }} />
                      <Button
                        onClick={() => {
                          window.location.href = `https://blog.tucar.app/posts/${blog.slug}`;
                        }}
                        size="small"
                        style={{ alignSelf: 'start' }}
                      >
                        Leer
                      </Button>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '25px',
              wrap: 'wrap',
            }}
          >
            <Box
              sx={{
                ...specialCardStyle,
                minWidth: 'unset',
                padding: '28px 10px',
                maxWidth: '40vw',
              }}
            >
              <Box
                sx={{
                  zIndex: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                  alignItems: 'center',
                }}
              >
                <Typography
                  align="center"
                  color={azulTucar}
                  style={{ fontSize: '14px', fontWeight: 600 }}
                >
                  ¿Cómo funciona el plan?
                </Typography>
                <img
                  src="https://storage.googleapis.com/tucar-app-web/public/driver.png"
                  alt="imagen"
                  style={{
                    width: '140px',
                    height: '95px',
                    borderRadius: '12px',
                    objectFit: 'contain',
                  }}
                />
                <Button
                  size="small"
                  style={{ width: 'max-content', alignSelf: 'center' }}
                  onClick={() => {
                    window.location.href = plan?.exclusive
                      ? 'https://tucar.com/plan-plus'
                      : 'https://tucar.com/plan-premium';
                  }}
                >
                  Saber más
                </Button>
              </Box>
            </Box>

            <Box
              sx={{
                ...specialCardStyle,
                minWidth: 'unset',
                padding: '28px 10px',
                maxWidth: '40vw',
              }}
            >
              <Box
                sx={{
                  zIndex: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                  alignItems: 'center',
                }}
              >
                <Typography
                  align="center"
                  color={azulTucar}
                  style={{ fontSize: '14px', fontWeight: 600, lineHeight: 1.2 }}
                >
                  Preguntas frecuentes
                </Typography>
                <img
                  src={womanDriver}
                  alt="imagen_question"
                  style={{
                    width: '120px',
                    height: '95px',
                    borderRadius: '20px',
                    objectFit: 'cover',
                  }}
                />

                <Button
                  size="small"
                  style={{ width: 'max-content', alignSelf: 'center' }}
                  onClick={() => {
                    window.location.href = 'https://tucar.com/preguntas-frecuentes';
                  }}
                >
                  Saber más
                </Button>
              </Box>
            </Box>
          </Box>
          <Button
            style={{ width: 'max-content', alignSelf: 'center' }}
            onClick={() => navigate('/app/v2/dashBoard', { replace: true })}
          >
            Ir a inicio
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default FinishStep;
