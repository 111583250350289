import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Typography, Button } from '@tucar/pit-stop';
import { azulTucar } from '../../../../themes/variants/TucarColors';
import ResumeCard from '../components/resumeCard';

const ResumeStep = ({ onNext }) => {
  return (
    <Box>
      <Box
        sx={{
          position: 'absolute',
          top: '0px',
          left: '0px',
          width: '100vw',
          height: '100vh',
          background: 'linear-gradient(180deg, #EAFFFE 0%, rgba(205, 201, 241, 0.80) 100%)',
          backdropFilter: 'blur(2px)',
          paddingInline: '27px',
          zIndex: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ResumeCard />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '30px 20px',
            paddingTop: '0px',
            width: 'calc(100vw - 50px)',
            gap: '10px',
          }}
        >
          <Typography
            align="center"
            color={azulTucar}
            style={{ fontSize: '18px', fontWeight: 600 }}
          >
            Días solicitados
          </Typography>
          <Typography
            color={azulTucar}
            align="center"
            style={{ fontSize: '14px', fontWeight: 400, lineHeight: '16px' }}
          >
            Después de solicitar cambios, revisa el resumen de &ldquo;Días Solicitados&rdquo;.
            Asegúrate de que todo esté en orden antes de enviar la solicitud a tu compañero.
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            paddingBottom: '30px',
            width: '100%',
          }}
        >
          <Typography color={azulTucar} style={{ fontSize: '14px', fontWeight: 500 }}>
            Paso 5/6
          </Typography>
          <Button size="small" onClick={onNext}>
            Siguiente
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

ResumeStep.propTypes = {
  onNext: PropTypes.func,
};

ResumeStep.defaultProps = {
  onNext: () => {},
};

export default ResumeStep;
