import { gql } from '@apollo/client';
import { setPlan } from '../../store';

const CREATE_DRIVER_PLAN = {
  mutation: gql`
    mutation CreateDriverPlan(
      $driverId: ID!
      $planId: ID!
      $selectedShifts: [String]!
      $exchangeAddress: String
    ) {
      createDriverPlanV2(
        driverId: $driverId
        planId: $planId
        selectedShifts: $selectedShifts
        exchangeAddress: $exchangeAddress
      ) {
        _id
        planId
        startDate
        selectedShifts {
          basePrice
          name
          endTime
        }
        plan {
          exclusive
          electric
          name
          active
          shifts {
            _id
          }
        }
      }
    }
  `,
  policies: {
    onCompleted: (response) => {
      if (response.createDriverPlanV2) {
        const { createDriverPlanV2 } = response;
        setPlan({ ...createDriverPlanV2 });
      }
    },
    errorPolicy: 'ignore',
  },
};

export default CREATE_DRIVER_PLAN;
