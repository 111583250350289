import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, BoxFlex, Button, Dropdown, Typography } from '@tucar/pit-stop';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { getAuthStore, getDriverStore, setVerifiedList } from '../../../../graphql/store';
import TucarLocationInputField from '../../info/TucarLocationInputField';
import GET_PREFERENCES from '../../../../graphql/querys/driver/getPreferences';
import LoadingProgress from '../../../../components/ui/LoadingProgress';
import CREATE_DRIVER_PLAN from '../../../../graphql/mutations/plan/createDriverPlan';
import CREATE_PREFERENCE from '../../../../graphql/mutations/plan/createPreference';
import EDIT_DRIVER_PLAN from '../../../../graphql/mutations/plan/editDriverPlan';
import EDIT_PREFERENCE from '../../../../graphql/mutations/plan/editPreference';

const shiftTranslations = {
  ALL: 'Turno completo',
  TC: 'Turno completo',
  FREE: 'Turno libre',
  TL: 'Turno libre',
  AM: 'Turno AM',
  PM: 'Turno PM',
  NIGHT: 'Turno Nocturno',
  RESERVED: 'Turno Reservado',
};

export const translateShift = (type) => {
  return shiftTranslations[type] || type;
};

const SimulationPlan = ({ selectedPlan, goBack }) => {
  const { verifiedList } = useReactiveVar(getDriverStore);

  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const { loading, data } = useQuery(GET_PREFERENCES.query, {
    variables: { entityId: uid },
    ...GET_PREFERENCES.policies,
  });

  const [preferences, setPreferences] = useState(null);
  const [updatePreferences, setUpdatePreferences] = useState(false);
  const [loadingHandler, setLoadingHandler] = useState(false);

  const [shift, setShift] = useState(null);
  const [createPlan, { loading: loadingCreatePlan, data: dataCreatePlan, error: errorCreatePlan }] =
    useMutation(CREATE_DRIVER_PLAN.mutation, CREATE_DRIVER_PLAN.policies);
  const [updatePlan, { loading: loadingUpdatePlan, data: dataUpdatePlan, error: errorUpdatePlan }] =
    useMutation(EDIT_DRIVER_PLAN.mutation, EDIT_DRIVER_PLAN.policies);
  const [
    createPreference,
    { loading: loadingCreatePreference, data: dataCreatePreference, error: errorCreatePreference },
  ] = useMutation(CREATE_PREFERENCE.mutation, CREATE_PREFERENCE.policies);
  const [
    updatePreference,
    { loading: loadingUpdatePreference, data: dataUpdatePreference, error: errorUpdatePreference },
  ] = useMutation(EDIT_PREFERENCE.mutation, EDIT_PREFERENCE.policies);

  const [planSuccess, setPlanSuccess] = useState(false);
  const [preferenceSuccess, setPreferenceSuccess] = useState(false);
  const [planError, setPlanError] = useState(false);
  const [preferenceError, setPreferenceError] = useState(false);

  const { shifts } = selectedPlan;
  const availableShifts = shifts
    .filter((shiftObj) => shiftObj.available)
    .map((elem) => {
      const { type, startTime, endTime, _id } = elem;
      return { name: `${translateShift(type)} (${startTime} a ${endTime} hrs)`, id: _id };
    });

  const handlePlan = () => {
    const { _id: planId } = selectedPlan;
    const variables = {
      driverId: uid,
      planId,
      selectedShifts: Array(7).fill(shift.id),
      exchangeAddress: preferences,
    };

    if (verifiedList?.plan) {
      updatePlan({ variables });
    } else {
      createPlan({ variables });
    }
  };

  const handlePreference = () => {
    const { _id: planId } = selectedPlan;
    const variables = {
      driverId: uid,
      planId,
      shiftId: shift.id,
      exchangeAddress: preferences,
    };

    if (updatePreferences) {
      updatePreference({ variables });
    } else {
      createPreference({ variables });
    }
  };

  useEffect(() => {
    if (
      !loadingCreatePlan &&
      !loadingUpdatePlan &&
      !loadingUpdatePreference &&
      !loadingCreatePreference
    ) {
      setLoadingHandler(false);
    }
  }, [loadingCreatePlan, loadingUpdatePlan, loadingUpdatePreference, loadingCreatePreference]);

  useEffect(() => {
    if (!loadingCreatePlan && !loadingUpdatePlan) {
      if (dataCreatePlan || dataUpdatePlan) {
        setPlanSuccess(true);
      }
      if (errorCreatePlan || errorUpdatePlan) {
        setPlanError(true);
      }
    }
  }, [loadingCreatePlan, loadingUpdatePlan]);

  useEffect(() => {
    if (!loadingCreatePreference && !loadingUpdatePreference) {
      if (dataCreatePreference || dataUpdatePreference) {
        setPreferenceSuccess(true);
      }
      if (errorCreatePreference || errorUpdatePreference) {
        setPreferenceError(true);
      }
    }
  }, [loadingCreatePreference, loadingUpdatePreference]);

  useEffect(() => {
    if (planSuccess && preferenceSuccess) {
      setVerifiedList({ ...verifiedList, plan: true });
      goBack();
    }
  }, [planSuccess, preferenceSuccess]);

  useEffect(() => {
    if (data && data?.getPreference && data?.getPreference?.exchangeAddress) {
      const exchangeAddress = data?.getPreference?.exchangeAddress?.address || null;
      setPreferences(exchangeAddress);
      if (exchangeAddress) {
        setUpdatePreferences(true);
      }
    }
  }, [data]);

  if (loading) {
    return (
      <Box
        style={{
          height: '100vh',
          width: '100vw',
          background: '#F0F0F3',
        }}
      >
        <LoadingProgress />
      </Box>
    );
  }
  return (
    <BoxFlex gap="25px" flexDirection="column" style={{ paddingInline: '20px', width: '100%' }}>
      <Dropdown
        fullWidth
        label="Preferencia horaria"
        placeholder="Selecciona"
        options={availableShifts}
        value={shift}
        onChange={(e) => setShift(e)}
        getOptionLabel={(option) => option.name}
      />

      <Box sx={{ paddingBottom: '20px' }}>
        <Box sx={{ paddingBottom: '15px' }}>
          <Typography
            style={{
              color: '#5B5D71',
              fontSize: '14px',
              lineHeight: 1.2,
              fontWeight: 600,
            }}
          >
            Dirección de preferencia
          </Typography>
        </Box>
        <TucarLocationInputField
          label="Dirección"
          placeholder="Dirección"
          name="address"
          style={{ flex: '1  1 100%', height: '50px' }}
          value={preferences || ''}
          selectorValue={preferences || ''}
          onChange={(newValue) => {
            setPreferences(newValue);
          }}
        />
      </Box>

      <BoxFlex flexDirection="column" gap="10px" style={{ paddingTop: '30px' }}>
        {planError && (
          <Button
            onClick={handlePlan}
            disabled={!shift}
            style={{ width: 'fit-content', alignSelf: 'center' }}
          >
            Reintentar enviar plan
          </Button>
        )}
        {preferenceError && (
          <Button
            onClick={handlePreference}
            disabled={!shift && !preferences}
            style={{ width: 'fit-content', alignSelf: 'center' }}
          >
            Reintentar enviar plan
          </Button>
        )}
        {!planError && !preferenceError && (
          <Button
            onClick={() => {
              setLoadingHandler(true);
              handlePlan();
              handlePreference();
            }}
            disabled={!shift || !preferences || loadingHandler}
            style={{ width: 'fit-content', alignSelf: 'center' }}
          >
            Enviar
          </Button>
        )}
      </BoxFlex>
    </BoxFlex>
  );
};

SimulationPlan.propTypes = {
  selectedPlan: PropTypes.shape({
    _id: PropTypes.string,
    shifts: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  goBack: PropTypes.func,
};

SimulationPlan.defaultProps = {
  selectedPlan: {
    _id: '',
    shifts: [],
  },
  goBack: () => {},
};

export default SimulationPlan;
