import React from 'react';
import PropTypes from 'prop-types';

import { Box, BoxFlex, Button, Icon } from '@tucar/pit-stop';
import StatusStep from './statusStep';

const BaseButton = ({ completed, title, goToFunction }) => {
  return (
    <BoxFlex flexDirection="column" gap="12px">
      <StatusStep completed={completed} />
      <Button
        contentStyle={{ lineHeight: 1, textAlign: 'left' }}
        fullWidth
        size="large"
        endAdornment={
          <Box style={{ transform: 'rotate(180deg)' }}>
            <Icon name="leftArrowSpecial" size="16px" color="#A8AABA" />
          </Box>
        }
        onClick={goToFunction}
      >
        {title}
      </Button>
    </BoxFlex>
  );
};

BaseButton.propTypes = {
  completed: PropTypes.bool,
  title: PropTypes.string,
  goToFunction: PropTypes.func,
};

BaseButton.defaultProps = {
  completed: false,
  title: '',
  goToFunction: () => {},
};

export default BaseButton;
