import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useReactiveVar } from '@apollo/client';
import { Box, IconButton, LinearProgress } from '@mui/material';
import { TextField, Typography } from '@tucar/pit-stop';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { FileUpload } from '@mui/icons-material';

import { azulTucar } from '../themes/variants/TucarColors';
import { getAuthStore } from '../graphql/store';

const iconStatusDoc = {
  Pending: 'success',
  Approved: null,
  Nothing: null,
  Errors: 'error',
  Rejected: 'warning',
};

const translationStatus = {
  Pending: '(Subido correctamente)',
  Approved: '',
  Nothing: '',
  Rejected: '(Documento rechazado)',
  Errors: '(Documento rechazado por errores)',
};

const UploadFileButton = ({
  label,
  status,
  url,
  fileType,
  fileName,
  handler,
  disableDelete,
  openError,
}) => {
  const [uploaded, setUploaded] = useState(false);
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);

  const handleUpload = (name, file, handlerChange) => {
    const dataUpload = new FormData();
    dataUpload.append('files', file, `${name}.${file.name.split('.').pop()}`);
    dataUpload.append('id', uid);
    const config = {
      method: 'post',
      url: `${process.env.DOCUMENT_GATEWAY_URL}/api/v1/cl/document/attach`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization:
          process.env.mode === 'development'
            ? null
            : `Apikey ${process.env.DOCUMENT_GATEWAY_API_KEY}`,
      },
      data: dataUpload,
    };
    axios(config)
      .then((response) => {
        const dataAux = {
          ...response.data[0],
          fileType: 'application/pdf, image/*',
          loadingRefetch: false,
        };
        handlerChange(dataAux.name, dataAux);
        setUploaded(false);
      })
      .catch(() => {
        openError();
        setUploaded(false);
      });
    return false;
  };

  if (uploaded) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          paddingY: '7px',
          gap: '12px',
          width: '100%',
          paddingInline: '30px',
        }}
      >
        <Typography
          style={{
            color: '#5B5D71',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: 1.2,
          }}
        >
          {label}
        </Typography>
        <Box
          sx={{
            height: '50px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <LinearProgress />
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingY: '7px',
        gap: '12px',
      }}
    >
      <Typography
        style={{
          color: '#5B5D71',
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: 1.2,
          paddingInline: '30px',
        }}
      >
        {label}
      </Typography>
      <Box sx={{ position: 'relative', width: '100vw', paddingInline: '30px' }}>
        <input
          disabled={disableDelete || status === 'Approved'}
          type="file"
          id="icon-button-file"
          accept={fileType}
          hidden={status === 'Approved'}
          onChange={(e) => {
            setUploaded(true);
            handleUpload(fileName, e.target.files[0], handler);
          }}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1,
            opacity: 0,
          }}
        />
        <TextField
          readOnly
          onlyLabel
          fullWidth
          value={uploaded ? 'Cargando...' : label}
          helperText={uploaded ? null : translationStatus[status]}
          helperType={uploaded ? null : iconStatusDoc[status]}
          endAdornment={
            status === 'Approved' ? (
              <IconButton size="small" type="submit" target="_blank" href={url} sx={{ padding: 0 }}>
                <VisibilityIcon size="small" sx={{ padding: 0, color: azulTucar }} />
              </IconButton>
            ) : (
              <Box>
                {status === 'Nothing' ? (
                  <IconButton
                    size="small"
                    aria-label="upload picture"
                    component="label"
                    sx={{ padding: 0 }}
                  >
                    <input
                      disabled={disableDelete || status === 'Approved'}
                      type="file"
                      id="icon-button-file"
                      accept={fileType}
                      hidden
                      onChange={(e) => {
                        setUploaded(true);
                        handler(fileName, e.target.files[0]);
                      }}
                    />
                    <FileUpload sx={{ color: azulTucar }} />
                  </IconButton>
                ) : (
                  <IconButton
                    disabled={disableDelete}
                    sx={{ padding: 0 }}
                    size="small"
                    aria-label="upload picture"
                    component="label"
                  >
                    <input
                      disabled={disableDelete || status === 'Approved'}
                      type="file"
                      id="icon-button-file"
                      accept={fileType}
                      hidden
                      onChange={(e) => {
                        setUploaded(true);
                        handler(fileName, e.target.files[0]);
                      }}
                    />
                    <AutorenewIcon
                      size="small"
                      sx={{ padding: 0, transform: 'rotate(90deg)', color: azulTucar }}
                    />
                  </IconButton>
                )}
              </Box>
            )
          }
        />
      </Box>
    </Box>
  );
};

UploadFileButton.propTypes = {
  label: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  handler: PropTypes.func.isRequired,
  fileType: PropTypes.string,
  disableDelete: PropTypes.bool,
  status: PropTypes.string,
  url: PropTypes.string,
  openError: PropTypes.func,
};

UploadFileButton.defaultProps = {
  disableDelete: false,
  status: 'Nothing',
  fileType: 'application/pdf, image/*',
  url: '',
  openError: () => {},
};

export default UploadFileButton;
