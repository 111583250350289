import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { Icon } from '@tucar/pit-stop';
import { CircularProgress } from '@mui/material';
import getGoogleMapsApiClient from '../../../helpers/googleApiClient';
import { StyledSelector, AutocompleteInput } from './styles';

const TucarLocationInputField = ({
  disabled,
  label,
  name,
  onChange,
  style,
  selectorValue,
  ...props
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const timeoutRef = useRef();
  const sessionTokenRef = useRef();
  const inputProps = {};

  const loadSuggestions = async (inputValue) => {
    clearTimeout(timeoutRef.current);

    if (!inputValue || inputValue.trim().length <= 3) {
      setSuggestions([]);
      return;
    }

    timeoutRef.current = setTimeout(async () => {
      setLoading(true);
      const google = await getGoogleMapsApiClient();
      if (google.maps && google.maps.places) {
        if (!sessionTokenRef.current) {
          sessionTokenRef.current = new google.maps.places.AutocompleteSessionToken();
        }
        new google.maps.places.AutocompleteService().getPlacePredictions(
          {
            input: inputValue,
            sessionToken: sessionTokenRef.current,
          },
          (predictions, status) => {
            if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
              setSuggestions([]);
              return;
            }
            if (status !== google.maps.places.PlacesServiceStatus.OK || !predictions) {
              return;
            }
            setSuggestions(() => [...predictions]);
          },
        );
      }
      setLoading(false);
    }, 400);
  };

  const handleLocationChange = (_, value) => {
    onChange(value.description);
  };

  const handleInputChange = async (event) => {
    await loadSuggestions(event.target.value.replace(/ /g, '+'));
  };

  const renderLabel = (suggestion) => {
    if (suggestion?.description) return suggestion.description;
    if (typeof suggestion === 'string') return suggestion;
    return '';
  };

  const checkOptionEqualToValue = (option, currentValue) => {
    if (option?.placeId === currentValue?.placeId) return true;
    if (option?.description === currentValue) return true;
    return currentValue?.description === '';
  };

  return (
    <StyledSelector
      ListboxProps={{
        style: {
          background: '#E3EDF7',
          boxShadow:
            '-4px -4px 9px 0px rgba(255, 255, 255, 0.88) inset, 4px 4px 14px 0px #D9D9D9 inset',
          padding: '0px',
          '&.MuiAutocompleteOption[ariaSelected="false"]': {
            border: '1px solid red',
          },
          '&.MuiAutocompleteOption': {
            fontSize: '14px',
            border: '1px solid red !important',
          },
          '&.MuiAutocomplete-option[aria-selected="true"]': {
            background: '#0057B8',
          },
        },
      }}
      value={selectorValue}
      onChange={handleLocationChange}
      options={suggestions}
      getOptionLabel={(suggestion) => renderLabel(suggestion)}
      isOptionEqualToValue={(option, currentValue) => checkOptionEqualToValue(option, currentValue)}
      disabled={disabled}
      disableClearable
      noOptionsText="Ingrese una ubicación válida para ver sugerencias."
      filterOptions={(options) => {
        return options;
      }}
      renderInput={(params) => (
        <AutocompleteInput
          disabled={disabled}
          name={name}
          variant="outlined"
          style={{ fontSize: '14px', height: 'auto' }}
          onChange={handleInputChange}
          {...params}
          {...inputProps}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                <Icon name="arrowDownSpecial" color="#0057B8" />
              </>
            ),
          }}
        />
      )}
      {...props}
    />
  );
};

TucarLocationInputField.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.shape(),
  selectorValue: PropTypes.string,
};

TucarLocationInputField.defaultProps = {
  disabled: false,
  label: 'Ubicación',
  name: 'Ingrese',
  onChange: () => {},
  style: {},
  selectorValue: '',
};

export default TucarLocationInputField;
