import React from 'react';
import { Box } from '@mui/material';
import NavBar from './navigationBar';

const NavigationBar = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#F0F0F3',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <NavBar />
    </Box>
  );
};

export default NavigationBar;
