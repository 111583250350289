export const standardWeekDaysNames = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const translateShiftsByDay = (config, options) => {
  const futureShifts = config.reduce((acc, { am, pm }, index) => {
    if (am && pm) return { ...acc, [standardWeekDaysNames[index]]: options?.ALL?.id };
    if (am) return { ...acc, [standardWeekDaysNames[index]]: options?.AM?.id };
    if (pm) return { ...acc, [standardWeekDaysNames[index]]: options?.PM?.id };
    return { ...acc, [standardWeekDaysNames[index]]: options?.FREE?.id };
  }, {});

  const auxTable = [...Array(7).keys()].map(
    (element) => futureShifts[standardWeekDaysNames[element]],
  );
  return auxTable;
};

export const complementConfig = (configAux) => {
  return configAux.map((elem) => {
    return { am: !elem.am, pm: !elem.pm };
  });
};
