const cleanRut = (value) => {
  return typeof value === 'string' ? value.replace(/[^0-9kK]+/g, '').toUpperCase() : '';
};

const preprocessRut = (rutInput) => {
  let rutValue = rutInput.replace('-', '');
  rutValue = rutValue.replace(/^0+/, '');
  const rutBody = rutValue.slice(0, -1);
  const initialVerificationDigit = rutValue.slice(-1).toUpperCase();
  const sanitizedRut = `${rutBody}-${initialVerificationDigit}`;
  return { rutValue, rutBody, initialVerificationDigit, sanitizedRut };
};

const getExpectedVerificationDigit = (rutValue, rutBody) => {
  let counter = 0;
  let multiplier = 2;
  for (let i = 1; i <= rutBody.length; i += 1) {
    const index = multiplier * rutValue.charAt(rutBody.length - i);
    counter += index;
    if (multiplier < 7) {
      multiplier += 1;
    } else {
      multiplier = 2;
    }
  }
  return 11 - (counter % 11);
};

const specialValuesValidation = (value) => {
  switch (value.toLowerCase()) {
    case 'k':
      return 10;
    case '0':
      return 11;
    default:
      return value;
  }
};

const checkRut = (rutString) => {
  const { rutValue, rutBody, initialVerificationDigit } = preprocessRut(rutString);
  if (rutBody.length < 6) {
    return false;
  }
  const expectedVerificationDigit = getExpectedVerificationDigit(rutValue, rutBody);
  const verificationDigit = specialValuesValidation(initialVerificationDigit);
  if (expectedVerificationDigit.toString() !== verificationDigit.toString()) {
    return false;
  }
  return true;
};

export const rutValidator = (rutInput) => {
  if (!rutInput || rutInput.length < 7) return false;
  const rutValue = rutInput.replace(/\./g, '');
  const rutRegex = /^[0-9]+-[0-9kK]{1}$/;
  if (rutRegex.test(rutValue) && checkRut(rutValue)) {
    return true;
  }
  return false;
};

export const formatRut = (value) => {
  const cleanValue = cleanRut(value);
  if (cleanValue.length <= 1) return cleanValue;
  let result = `${cleanValue.slice(-4, -1)}-${cleanValue.substring(cleanValue.length - 1)}`;
  for (let i = 4; i < cleanValue.length; i += 3)
    result = `${cleanValue.slice(-3 - i, -i)}.${result}`;
  return result;
};
