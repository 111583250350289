import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';

import LoadingScreen from '../components/LoadingScreen';
import { getRefreshToken as fetchRefreshToken, getTokenMetadata } from '../api/auth';
import {
  getRefreshToken,
  authStore,
  isLoading,
  logout,
  isAuthenticated,
  isExpired,
  setAuth,
  setUserId,
  setExpirationDate,
} from '../graphql/store';

const AuthContext = ({ children }) => {
  const reactiveAuthStore = useReactiveVar(authStore);
  const authToken = getRefreshToken();

  const [loadingAuth, setLoadingAuth] = useState(isLoading());

  const handleSuccessfulRefresh = ({ data }) => setAuth(data);

  const handleSuccessfulMetadataRequest = ({ data }) => {
    const { user_id: userId } = data || {};
    setUserId(userId);
    setLoadingAuth(false);
  };

  const handleError = () => {
    setLoadingAuth(false);
    logout({ hardLogout: true });
  };

  const refreshQuery = async (currentRefreshToken) => {
    return fetchRefreshToken(
      { refresh_token: currentRefreshToken },
      { onSuccess: handleSuccessfulRefresh, onError: handleError },
    );
  };

  const refresh = useCallback(
    async (refreshToken) => {
      setExpirationDate();
      const { data: authData } = (await refreshQuery(refreshToken)) ?? { data: {} };
      const { access_token: accessToken } = authData || {};
      if (!reactiveAuthStore?.data?.uid)
        await getTokenMetadata(accessToken, { onSuccess: handleSuccessfulMetadataRequest });
    },
    [refreshQuery, reactiveAuthStore?.data?.uid],
  );

  useEffect(() => {
    if (isAuthenticated() && authToken && (!reactiveAuthStore?.accessToken || isExpired())) {
      setLoadingAuth(true);
      refresh(authToken);
    }
  }, [authToken, reactiveAuthStore?.accessToken, isExpired()]);
  return <>{loadingAuth ? <LoadingScreen /> : children}</>;
};

AuthContext.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AuthContext;
