import React from 'react';
import { Card, GroupButton } from '@tucar/pit-stop';
import { Typography, Box } from '@mui/material';
import getUniqueKey from '../../../../helpers/customKey';
import { capitalizeDate, getAddDate, getDateList } from '../../../../helpers/dateFormat';

const SelectionPeriod = () => {
  const config = Array(7).fill({ am: true, pm: false });

  return (
    <Card style={{ padding: '26px 60px', width: '100%' }}>
      <Typography
        style={{
          color: '#1F89FF',
          lineHeight: '1.2',
          fontSize: '14px',
          fontWeight: 400,
          paddingBottom: '5px',
        }}
      >
        Periodo
      </Typography>
      <Typography
        style={{
          color: '#1F89FF',
          lineHeight: '1.2',
          fontSize: '14px',
          fontWeight: 500,
        }}
      >
        {capitalizeDate(new Date(), 'DD/MM/YYYY')} al {getAddDate(new Date(), 'DD/MM/YYYY', 6)}
      </Typography>
      <Box
        sx={{
          paddingTop: '20px',
          display: 'flex',
          flexDirection: 'column',
          gap: '7px',
          width: '100%',
        }}
      >
        {getDateList(new Date()).map((date, index) => {
          const { am, pm } = config[index];
          const isToday = date.getDate() === new Date().getDate();
          return (
            <Box
              key={getUniqueKey()}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box
                  sx={{
                    width: '6px',
                    height: '6px',
                    borderRadius: '14px',
                    backgroundColor: isToday ? '#1F89FF' : 'transparent',
                  }}
                />
                <Typography
                  style={{
                    lineHeight: 1.2,
                    color: isToday ? '#1F89FF' : '#2D2F39',
                    fontSize: '18px',
                    fontWeight: 600,
                  }}
                >
                  {capitalizeDate(date, 'DD')}
                </Typography>
                <Typography
                  style={{
                    lineHeight: 1.2,
                    color: isToday ? '#1F89FF' : '#5B5D71',
                    fontSize: '14px',
                    fontWeight: 500,
                  }}
                >
                  {capitalizeDate(date, 'ddd').replace('.', '')}
                </Typography>
              </Box>
              <Box
                sx={{
                  position: 'relative',
                  zIndex: 1,
                  pointerEvents: 'none',
                }}
              >
                <GroupButton
                  disabled={isToday}
                  padding="10px 14px"
                  data={[
                    {
                      label: 'AM',
                      active: am,
                      color: am && pm ? '#95D21C' : null,
                      onClick: () => {},
                    },
                    {
                      label: 'PM',
                      active: pm,
                      color: am && pm ? '#95D21C' : null,
                      onClick: () => {},
                    },
                  ]}
                />
              </Box>
            </Box>
          );
        })}
      </Box>
    </Card>
  );
};

export default SelectionPeriod;
