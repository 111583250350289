import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Box, BoxFlex, Button } from '@tucar/pit-stop';
import { Fade } from '@mui/material';

import { useReactiveVar } from '@apollo/client';
import TopNav2 from '../../../components/ui/TopNav/newTopNav';
import HeaderPlan from './HeaderPlan';
import PlanDisplay from './PlanDisplay';
import SimulationPlan from './SimulationPlan';
import { getDriverStore } from '../../../graphql/store';
import NewSubscriptionPlan from '../../appV2/subscription';

const OnBoardingPlan = ({ goBack }) => {
  const { verifiedList } = useReactiveVar(getDriverStore);
  const [editPlan, setEditPlan] = useState(false);

  const [mode, setMode] = useState('light');
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [headerData, setHeaderData] = useState({
    title: 'Escoge tu plan',
    description:
      'Contamos con distintos planes y vehículos para que escojas según tu preferencia :)',
  });

  const setHandler = (plan) => {
    setSelectedPlan(plan);
    setHeaderData({
      title: 'Escoge tu horario',
      description:
        'Si cuentas con un plan compartido, es posible editar los turnos semanalmente con tu compañero una vez termines el proceso de inscripción.',
    });
  };

  if (verifiedList?.plan && !editPlan) {
    return (
      <Box style={{ background: '#F0F0F3', height: '100vh', width: '100vw' }}>
        <TopNav2 prevPage="/app/onboarding" title="" handlePageChange={goBack} />
        <BoxFlex style={{ paddingBottom: '30px', justifyContent: 'center' }}>
          <Button onClick={() => setEditPlan(!editPlan)}>Editar plan</Button>
        </BoxFlex>
        <Box style={{ paddingInline: '10px' }}>
          <NewSubscriptionPlan onBoarding />
        </Box>
      </Box>
    );
  }

  return (
    <Fade in timeout={1000}>
      <Box
        style={{
          height: '100vh',
          width: '100vw',
          overflow: 'hidden',
          background: mode === 'light' || selectedPlan !== null ? '#F0F0F3' : '#000E1F',
        }}
      >
        <TopNav2 prevPage="/app/onboarding" title="" handlePageChange={goBack} />
        <BoxFlex
          flexDirection="column"
          gap="20px"
          style={{
            alignItems: 'center',
            paddingInline: '30px',
            width: '100%',
            paddingBottom: '50px',
          }}
        >
          <HeaderPlan
            mode={mode === 'light' || selectedPlan !== null ? 'light' : 'dark'}
            title={headerData.title}
            description={headerData.description}
          />
          {!selectedPlan && <PlanDisplay setMode={setMode} mode={mode} setPlan={setHandler} />}
          {selectedPlan && <SimulationPlan selectedPlan={selectedPlan} goBack={goBack} />}
        </BoxFlex>
      </Box>
    </Fade>
  );
};

OnBoardingPlan.propTypes = {
  goBack: PropTypes.func,
};

OnBoardingPlan.defaultProps = {
  goBack: () => {},
};

export default OnBoardingPlan;
