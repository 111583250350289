import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from '@tucar/pit-stop';
import { Box } from '@mui/material';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { useAuthParams } from '../../../hooks';
import { revokeToken } from '../../../api/auth';
import { getRefreshToken, getAuthStore, logout } from '../../../graphql/store';

import { azulTucar } from '../../../themes/variants/TucarColors';

const LogOut = ({ showIcon, noLinealOnboarding }) => {
  const { getLogoutUrl } = useAuthParams();

  const locallyLogout = () => {
    logout();
    window.location.href = getLogoutUrl();
  };

  const onClickLogoutHandler = async () => {
    const refreshToken = getRefreshToken();
    const { accessToken } = getAuthStore();
    await revokeToken(accessToken, { onError: locallyLogout });
    await revokeToken(refreshToken, {
      onError: locallyLogout,
      onSuccess: locallyLogout,
    });
  };

  if (noLinealOnboarding) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        onClick={onClickLogoutHandler}
      >
        <FeatherIcon icon="log-out" stroke={azulTucar} />
      </Box>
    );
  }

  return (
    <Button fullWidth data-testid="logOutButton" variant="ghost" onClick={onClickLogoutHandler}>
      {showIcon ? <Icon name="exitToAppOutlined" /> : null}
      Cerrar sesión
    </Button>
  );
};

LogOut.propTypes = {
  showIcon: PropTypes.bool,
  noLinealOnboarding: PropTypes.bool,
};

LogOut.defaultProps = {
  showIcon: true,
  noLinealOnboarding: false,
};

export default LogOut;
