import getUniqueKey from '../../../helpers/customKey';
import { authStore } from './model';

export const getExpirationDate = () => {
  return localStorage.getItem('exdt') ? localStorage.getItem('exdt') : null;
};

export const getRefreshToken = () =>
  localStorage.getItem('rtn') ? localStorage.getItem('rtn') : null;

export const getAuthProvider = () => 'Bearer';

export const getRandomState = () => {
  const originalRandomState = localStorage.getItem('rnSt');
  if (originalRandomState) return originalRandomState;
  const randomState = getUniqueKey();
  localStorage.setItem('rnSt', randomState);
  return randomState;
};

export const isAuthenticated = () =>
  localStorage.getItem('iad') ? localStorage.getItem('iad') : false;

export const isExpired = () => {
  const date = getExpirationDate();
  if (date) return new Date().getTime() > parseInt(date, 10);
  return true;
};

export const getAuthStore = authStore;
