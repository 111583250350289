import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Collapse, Typography, IconButton, Stack } from '@mui/material';
import { Card } from '@tucar/pit-stop';
import { useReactiveVar } from '@apollo/client';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { getAuthStore } from '../../../../graphql/store';
import BankAccountForm from '../../../../components/ui/BankAccountForm';

const AddAccountBank = ({ refetch, addBank }) => {
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);

  const [values, setValues] = useState({
    uid,
    rol: 'Driver',
    bank: '',
    accountType: '',
    account: '',
    name: '',
    rut: '',
    email: '',
  });

  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  const handleSend = () => {
    setValues((currentValues) => ({
      ...currentValues,
      account: values.account.split(' ').join('').trim(),
    }));
    addBank(values);
    refetch();
  };

  return (
    <Box sx={{ paddingInline: '20px' }}>
      <Card
        id="accounting-profile-add"
        style={{ backgroundColor: open ? '#FFF' : '#F3F3F3', transition: 'background-color 0.8s' }}
      >
        <Stack
          onClick={handleClick}
          paddingLeft="7px"
          paddingRight="4px"
          sx={{
            width: '100%',
            borderRadius: open ? '15px 15px 0px 0px' : '15px',
          }}
          direction="row"
          alignItems="center"
          height="45px"
          justifyContent="space-between"
        >
          <Box paddingLeft="10px" display="flex" alignItems="center" gap="3%" width="66%">
            <AddCircleOutlineIcon />
            <Typography
              sx={{
                color: '#333333',
                fontSize: '14px',
                fontFamily: 'Poppins-Regular',
                lineHeight: 1,
              }}
            >
              Agregar otra cuenta
            </Typography>
          </Box>

          {open ? (
            <IconButton onClick={handleClick}>
              <KeyboardArrowUpOutlinedIcon fontSize="small" sx={{ color: '#333333' }} />
            </IconButton>
          ) : (
            <IconButton onClick={handleClick}>
              <KeyboardArrowDownOutlinedIcon fontSize="small" sx={{ color: '#333333' }} />
            </IconButton>
          )}
        </Stack>

        <Collapse
          sx={{
            borderRadius: open ? '0px 0px 7px 7px' : '7px',
            paddingInline: '20px',
            width: '100%',
          }}
          in={open}
          timeout="auto"
          unmountOnExit
        >
          <BankAccountForm values={values} setValues={setValues} handleSend={handleSend} />
        </Collapse>
      </Card>
    </Box>
  );
};

AddAccountBank.propTypes = {
  refetch: PropTypes.func,
  addBank: PropTypes.func.isRequired,
};

AddAccountBank.defaultProps = {
  refetch: () => {},
};

export default AddAccountBank;
