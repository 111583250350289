import React, { useState } from 'react';

import { Fade, Box } from '@mui/material';
import SwipeWrapper from '../../../components/buttons/SwipeButton/SwipeWrapper';
import getUniqueKey from '../../../helpers/customKey';
import AnimatedStep from '../../auth/signUp/animatedStep';
import YouTubeEmbed from './youtubeEmbed';

const linksObjects = [
  {
    title: 'Bienvenido a Tucar',
    url: 'https://youtu.be/3BPl580hRaU?si=W2hN-G9AO46q8TaN',
  },
  {
    title: '¿Cómo incrementar tus fanancias en Uber?',
    url: 'https://youtu.be/zNMwd8Zf8KQ?si=aViC8X4L29C4Irk8',
  },
  {
    title: 'Conoce el plan compartido de Tucar',
    url: 'https://youtu.be/t9K8FiFkD5Q?si=z_RpSvve1fEm73cL',
  },
  {
    title: 'Conoce el plan carga nocturna de Tucar',
    url: 'https://youtu.be/Jupqxv5bvQw?si=1j3mXwStLcIezvaz',
  },
  {
    title: '¿Cómo mejorar tu rendimiento como conductor?',
    url: 'https://youtu.be/BBUkckhGSRE?si=szuoxLE4o2GZZvdf',
  },
  {
    title: '¿Qué hacer en caso de siniestro?',
    url: 'https://youtu.be/egN3DNTJZuU?si=_nwyZVo69KWvrN97',
  },
  {
    title: 'Conoce nuestro Kia Niro EV',
    url: 'https://youtu.be/zNMwd8Zf8KQ?si=VuiqDJNf0q0gGW_w',
  },
  {
    title: '¿Cómo leer tus liquidaciones en la aplicación?',
    url: 'https://youtu.be/1Hhn4goSIaI?si=kNm3apAWyq7J9WCI',
  },
  {
    title: '¿Cómo dar de baja el servicio en Tucar?',
    url: 'https://youtu.be/_KjY7ewC3gQ?si=fXBE29co_7S--iAk',
  },
];

const TutorialVideos = () => {
  const [index, setIndex] = useState(0);

  return (
    <Box sx={{ marginTop: '30px' }}>
      <SwipeWrapper
        threshold={20}
        onSwipeRight={() => setIndex(index + 1 < linksObjects.length ? index + 1 : 0)}
        onSwipeLeft={() => setIndex(index - 1 >= 0 ? index - 1 : linksObjects.length - 1)}
      >
        <Fade
          key={getUniqueKey()}
          in
          timeout={1000}
          sx={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box>
            {index > 0 && <YouTubeEmbed url={linksObjects[index - 1].url} position="left" />}

            <Box key={getUniqueKey()} sx={{ display: 'flex', justifyContent: 'center' }}>
              <YouTubeEmbed
                title={linksObjects[index].title}
                url={linksObjects[index].url}
                position="center"
                focusCard
              />
            </Box>

            {index < linksObjects.length - 1 && (
              <YouTubeEmbed url={linksObjects[index + 1].url} position="right" />
            )}
          </Box>
        </Fade>
      </SwipeWrapper>

      <Box sx={{ paddingTop: '20px' }}>
        <AnimatedStep
          index={index}
          cantSteps={linksObjects.length}
          primaryColor="#1F89FF"
          widthActive={18}
          heightActive={18}
          widthDisable={10}
          heightDisable={10}
          borderRadius="100%"
        />
      </Box>
    </Box>
  );
};

TutorialVideos.propTypes = {};

TutorialVideos.defaultProps = {};

export default TutorialVideos;
