/* eslint-disable import/no-mutable-exports */
import { ApolloClient, createHttpLink, InMemoryCache, concat, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import * as Sentry from '@sentry/react';

import { getRefreshToken as fetchRefreshToken, getTokenMetadata } from '../api/auth';
import {
  isExpired,
  setExpirationDate,
  getRefreshToken,
  getAuthStore,
  setAuth,
  setError,
  setLoading,
  logout,
  setUserId,
} from '../graphql/store';

const httpLink = createHttpLink({ uri: process.env.API_GATEWAY });

const handleSuccessfulRefresh = ({ data }) => {
  setAuth(data);
  setLoading(false);
};

const handleError = () => {
  logout({ hardLogout: true });
  setLoading(false);
};

const handleAccessTokenError = () => {
  setAuth({
    accessToken: false,
    data: {
      email: false,
      firstname: false,
      lastname: false,
      uid: false,
      userType: false,
      verified: false,
    },
  });
  setError(true);
};

const refreshTokenQuery = async (currentRefreshToken) => {
  return fetchRefreshToken(
    { refresh_token: currentRefreshToken },
    { onSuccess: handleSuccessfulRefresh, onError: handleError },
  );
};

const buildErrorScope = (scope, operation, response) => {
  if (operation) {
    scope.setTag('kind', operation?.operationName);
    scope.setExtra('query', operation?.query?.loc?.source);
    scope.setExtra('variables', operation?.variables);
  }
  scope.setExtra('response', response?.data);
};

const getAccessToken = async (currentRefreshToken, errorOrigin) => {
  const authStore = getAuthStore();
  const { data: authStoreData } = authStore || {};
  const { uid: userId } = authStoreData || {};
  if ((currentRefreshToken && isExpired()) || errorOrigin) {
    try {
      setExpirationDate();
      const { data: refreshQueryData } = (await refreshTokenQuery(currentRefreshToken)) || {};
      const { access_token: accessToken } = refreshQueryData || {};
      if (!userId)
        await getTokenMetadata(accessToken, {
          onSuccess: ({ data: userData }) => setUserId(userData?.user_id),
        });
      return { accessToken: accessToken || null };
    } catch {
      handleAccessTokenError();
      return { accessToken: null };
    }
  }
  return getAuthStore();
};

const authMiddleware = setContext(async (_, { headers, forRefreshToken }) => {
  const currentRefreshToken = getRefreshToken();
  const { accessToken } = await getAccessToken(currentRefreshToken);
  return {
    headers: {
      ...headers,
      Authorization: accessToken && !forRefreshToken ? `Bearer ${accessToken}` : '',
    },
  };
});

const cache = new InMemoryCache({
  typePolicies: {
    getEventsByUser: {
      merge: true,
    },
  },
});

const errorLink = onError(({ graphQLErrors, networkError, operation, response, forward }) => {
  if (graphQLErrors) {
    const { headers: oldHeaders, ignoreError, ignorePattern } = operation.getContext();
    graphQLErrors.forEach(async ({ extensions, message, locations, path }) => {
      if (extensions.code === 'UNAUTHENTICATED') {
        const { accessToken } = await getAccessToken(getRefreshToken(), true);
        operation.setContext({
          headers: {
            ...oldHeaders,
            authorization: `Bearer ${accessToken}`,
          },
        });
        return forward(operation);
      }
      if (ignoreError) return null;
      if (ignorePattern) {
        const { pattern = [], mandatory = true } = ignorePattern || {};
        if (mandatory && pattern.every((term) => message?.includes(term))) return null;
        if (!mandatory && pattern.some((term) => message?.includes(term))) return null;
      }
      return Sentry.withScope((scope) => {
        buildErrorScope(scope, operation, response);
        Sentry.captureMessage(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );
      });
    });
  }
  if (networkError) {
    Sentry.withScope((scope) => {
      buildErrorScope(scope, operation, response);
      Sentry.captureException(`[Network error]: ${networkError}`);
    });
    Sentry.captureException(`[Network error]: ${networkError}`);
  }
});

const client = new ApolloClient({
  cache,
  link: from([errorLink, concat(authMiddleware, httpLink)]),
});

export default client;
