/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { BoxFlex, Loader, Typography } from '@tucar/pit-stop';
import { useQuery, useReactiveVar, useMutation, NetworkStatus } from '@apollo/client';
import { getAuthStore } from '../../../../graphql/store';
import AccountBank from './AccountBank';
import AddAccountBank from './AddAccountBank';
import GET_DRIVER_BANKS from '../../../../graphql/querys/driver/getBanks';
import SELECT_BANK from '../../../../graphql/mutations/driver/selectBank';
import REMOVE_BANK from '../../../../graphql/mutations/driver/removeBank';
import ADD_BANK from '../../../../graphql/mutations/driver/addBank';
import LoadingProgress from '../../../../components/ui/LoadingProgress';
import ErrorModal from '../../../../components/ui/ErrorModal';
import TopNav2 from '../../../../components/ui/TopNav/newTopNav';

const AccountingInfo = ({ onBoarding, goBack }) => {
  const [openError, setOpenError] = useState(false);

  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);

  const [updateBank] = useMutation(SELECT_BANK.mutation);
  const [removeBank] = useMutation(REMOVE_BANK.mutation);
  const [addBank] = useMutation(ADD_BANK.mutation);
  const { loading, data, refetch, networkStatus } = useQuery(GET_DRIVER_BANKS.query, {
    ...GET_DRIVER_BANKS.policies,
    variables: {
      uid,
    },
  });
  const [normalBanks, setNormalBanks] = useState([]);

  useEffect(() => {
    if (data?.getDriverAccounting?.bankAccount) {
      setNormalBanks(
        data?.getDriverAccounting?.bankAccounts.filter((bank) => {
          if (data?.getDriverAccounting?.bankAccount) {
            return bank._id !== data?.getDriverAccounting?.bankAccount?._id;
          }
          return true;
        }),
      );
    }
  }, [data, loading, networkStatus]);

  const createBank = async (variables) => {
    try {
      await addBank({
        variables,
      });
      refetch();
    } catch (e) {
      setOpenError(true);
    }
  };

  const deleteBank = async (variables) => {
    try {
      await removeBank({
        variables,
      });
    } catch (e) {
      setOpenError(true);
    }
  };

  const selectBank = async (variables) => {
    try {
      await updateBank({
        variables,
      });
    } catch (e) {
      setOpenError(true);
    }
  };

  if (onBoarding && (loading || networkStatus === NetworkStatus.refetch)) {
    return (
      <Box sx={{ minHeight: '100vh', background: '#F0F0F3' }}>
        <TopNav2 title="" prevPage="/app/onboarding" handlePageChange={goBack} />
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoadingProgress />
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ minHeight: '100vh', background: '#F0F0F3' }}>
      <ErrorModal
        open={openError}
        onClose={() => {
          setOpenError(false);
        }}
        content="Ups, ha ocurrido un error de nuestra parte. Contacta a soporte pinchando en el chat"
        title="Error al editar cuentas bancarias"
      />
      {onBoarding ? (
        <BoxFlex flexDirection="column" style={{ padding: '0px 0px' }}>
          <TopNav2 title="" prevPage="/app/onboarding" handlePageChange={goBack} />
          <Typography
            style={{
              paddingInline: '30px',
              fontSize: '28px',
              fontWeight: 600,
              letterSpacing: 0.56,
              color: '#0057B8',
              paddingBottom: '15px',
            }}
          >
            Ingresa tu cuenta de depósito
          </Typography>
          <Typography
            style={{
              paddingInline: '30px',
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: 1.5,
              letterSpacing: 0.28,
              color: '#0057B8',
              paddingBottom: '63px',
            }}
          >
            Una vez completados tus datos, podrás acceder fácilmente a todos los beneficios y
            servicios que ofrecemos.
          </Typography>
        </BoxFlex>
      ) : null}
      {loading || networkStatus === NetworkStatus.refetch ? (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Loader size={32} pointRadio="4px" />
        </Box>
      ) : (
        <Box margin={onBoarding ? '0px' : '15px 10px 40px 10px'}>
          {data?.getDriverAccounting ? (
            <Box sx={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
              <AddAccountBank addBank={createBank} />
              {data.getDriverAccounting.bankAccount ? (
                <AccountBank
                  key={`${data.getDriverAccounting.bankAccount._id}`}
                  bank={data.getDriverAccounting.bankAccount}
                  removeFunction={deleteBank}
                  refetch={refetch}
                  principal
                />
              ) : null}
              {normalBanks.map((bank) => (
                <AccountBank
                  key={`${bank._id}`}
                  bank={bank}
                  selectionFunction={selectBank}
                  removeFunction={deleteBank}
                  refetch={refetch}
                />
              ))}
            </Box>
          ) : (
            <AddAccountBank addBank={createBank} />
          )}
        </Box>
      )}
    </Box>
  );
};

export default AccountingInfo;

AccountingInfo.propTypes = {
  onBoarding: PropTypes.bool,
  goBack: PropTypes.func,
};

AccountingInfo.defaultProps = {
  onBoarding: false,
  goBack: () => {},
};
