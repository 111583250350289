import React, { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import PropTypes from 'prop-types';

import { getDriverStore } from '../../../graphql/store';
import LoadingScreen from '../../../components/LoadingScreen';
import { useUberParams } from '../../../hooks';
import UberBond from './uberBond';

const OnBoardingApps = ({ goBack }) => {
  const { verifiedList } = useReactiveVar(getDriverStore);
  const { uberUrl } = useUberParams();

  useEffect(() => {
    if (!verifiedList?.app) {
      window.location.href = uberUrl;
    }
  }, [verifiedList?.app]);

  if (verifiedList?.app) {
    return <UberBond goBack={goBack} />;
  }

  return <LoadingScreen content="...Redirigiendo a Uber..." />;
};

OnBoardingApps.propTypes = {
  goBack: PropTypes.func,
};

OnBoardingApps.defaultProps = {
  goBack: () => {},
};

export default OnBoardingApps;
