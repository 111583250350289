import { gql } from '@apollo/client';

const UPDATE_SOURCE = {
  mutation: gql`
    mutation UpdateLeadSource($uid: ID!, $source: String!) {
      updateLeadSource(leadId: $uid, source: $source) {
        source
      }
    }
  `,
  policies: {
    fetchPolicy: 'no-cache',
    errorPolicie: 'ignore',
  },
};

export default UPDATE_SOURCE;
