import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@tucar/pit-stop';
import { useReactiveVar } from '@apollo/client';
import { getAuthStore } from '../../../graphql/store';
import { azulTucar } from '../../../themes/variants/TucarColors';
import Emoji from '../../../components/extras/emojis';

const WelcomeStep = ({ onNext }) => {
  const navigate = useNavigate();

  const {
    data: { firstname },
  } = useReactiveVar(getAuthStore);

  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: 3,
        top: 0,
        left: 0,
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backdropFilter: 'blur(2px)',
        background:
          'linear-gradient(180deg, rgba(234, 255, 254, 0.90) 0%, rgba(205, 201, 241, 0.90) 100%)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '50px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingInline: '30px',
          }}
        >
          <Typography
            align="center"
            color={azulTucar}
            style={{ fontSize: '36px', fontWeight: 500, lineHeight: 1.2 }}
          >
            Saludos,
          </Typography>
          <Typography
            align="center"
            color={azulTucar}
            style={{ lineHeight: 1.2, fontSize: '36px', fontWeight: 600 }}
          >
            {firstname[0].toUpperCase() + firstname.slice(1).toLowerCase()}
            <Emoji size="36px" symbol="🖖" label="emoji" />
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingInline: '40px',
            alignItems: 'center',
          }}
        >
          <Typography align="center" color="#5B5D71" style={{ fontSize: '14px', fontWeight: 500 }}>
            Estamos emocionados de tenerte aquí. Este tutorial te guiará a través de todas las
            características geniales que nuestra app tiene para ofrecerte.
          </Typography>
          <Typography color={azulTucar} style={{ fontSize: '14px', fontWeight: 500 }}>
            ¡Comencemos a explorar juntos!
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          height: '250px',
          width: '100%',
          display: 'flex',
          justifySelf: 'flex-end',
          gap: '10px',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          zIndex: 4,
          overflow: 'hidden',
          '::before': {
            content: '""',
            position: 'absolute',
            top: '55%',
            left: '50%',
            height: '544px',
            width: '544px',
            transform: 'translate(-50%, -25%)',
            borderRadius: '544px',
            border: '2px solid #FFF',
            background: 'rgba(255, 255, 255, 0.30)',
            backdropFilter: 'blur(2px)',
            zIndex: 0,
          },
        }}
      >
        <Button onClick={onNext}>Comenzar</Button>

        <Button variant="ghost" onClick={() => navigate('/app/v2/dashBoard', { replace: true })}>
          Saltar tutorial
        </Button>
      </Box>
    </Box>
  );
};

export default WelcomeStep;

WelcomeStep.propTypes = {
  onNext: PropTypes.func,
};

WelcomeStep.defaultProps = {
  onNext: () => {},
};
