import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { Fade, Box } from '@mui/material';
import { Loader } from '@tucar/pit-stop';
import CardWrapper from './CardWrapper';
import GET_CONFIG_PLANS_V2 from '../../../../graphql/querys/plan/getConfigPlansV2';
import getUniqueKey from '../../../../helpers/customKey';
import SwipeWrapper from '../../../../components/buttons/SwipeButton/SwipeWrapper';
import AnimatedStep from '../../../auth/signUp/animatedStep';

const PlanDisplay = ({ setMode, mode, setPlan }) => {
  const [planList, setPlanList] = useState([]);
  const { data, loading } = useQuery(GET_CONFIG_PLANS_V2.query);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (data?.getConfigPlans) {
      setPlanList(data.getConfigPlans.filter((plan) => plan.active));
    }
  }, [data, loading]);

  useEffect(() => {
    if (planList.length > 0 && planList[index]) {
      setMode(planList[index].exclusive && planList[index].electric ? 'dark' : 'light');
    }
  }, [index, planList]);

  if (loading || planList.length < 2)
    return (
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
        }}
      >
        <Loader size={32} pointRadio="4px" />
      </Box>
    );

  return (
    <Box>
      <SwipeWrapper
        threshold={50}
        onSwipeRight={() => setIndex(index + 1 < planList.length ? index + 1 : index)}
        onSwipeLeft={() => setIndex(index - 1 >= 0 ? index - 1 : index)}
      >
        <Fade
          key={getUniqueKey()}
          in
          timeout={1000}
          sx={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box>
            {index > 0 && (
              <CardWrapper
                key={getUniqueKey()}
                title={planList[index - 1].name}
                incomeList={planList[index - 1].includes}
                position="left"
                darkMode={planList[index - 1].exclusive && planList[index - 1].electric}
              />
            )}

            <CardWrapper
              key={getUniqueKey()}
              title={planList[index].name}
              incomeList={planList[index].includes}
              focusCard
              disabled={!planList[index].electric}
              onClick={() => setPlan(planList[index])}
            />

            {index < planList.length - 1 && (
              <CardWrapper
                key={getUniqueKey()}
                title={planList[index + 1].name}
                incomeList={planList[index + 1].includes}
                position="right"
                darkMode={planList[index + 1].exclusive && planList[index + 1].electric}
              />
            )}
          </Box>
        </Fade>
      </SwipeWrapper>

      <Box sx={{ paddingTop: '40px' }}>
        <AnimatedStep
          index={index}
          cantSteps={planList.length}
          primaryColor={mode === 'dark' ? '#FFF' : '#1F89FF'}
          widthActive={18}
          heightActive={18}
          widthDisable={10}
          heightDisable={10}
          borderRadius="100%"
        />
      </Box>
    </Box>
  );
};

PlanDisplay.propTypes = {
  setMode: PropTypes.func,
  mode: PropTypes.string,
  setPlan: PropTypes.func,
};

PlanDisplay.defaultProps = {
  setMode: () => {},
  mode: 'light',
  setPlan: () => {},
};

export default PlanDisplay;
