import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { BarGraph, Button, Card, Icon, IconButton, Typography } from '@tucar/pit-stop';
import { useReactiveVar } from '@apollo/client';
import { getAuthStore } from '../../../graphql/store';
import { azulTucar } from '../../../themes/variants/TucarColors';
import PressIconButton from '../../../components/buttons/PressIconButton';
import IconInfo from '../../../components/ui/IconInfoProfitCard';
import { backgroundStyle, currentData } from './aux';
import Emoji from '../../../components/extras/emojis';
import SideBarStep from './componentsSteps/SideBarStep';
import CalendarStep from './componentsSteps/CalendarStep';
import GainingStep from './componentsSteps/GainingStep';
import WaitingStep from './componentsSteps/WaitingStep';
import ShortcutStep from './componentsSteps/ShortcutStep';
import UberKmStep from './componentsSteps/UberKmStep';
import GraphStep from './componentsSteps/GraphStep';

const BaseDashboard = ({ step, onNext }) => {
  const {
    data: { firstname },
  } = useReactiveVar(getAuthStore);

  return (
    <Box
      sx={{
        ...backgroundStyle,
        ...(['Shortcuts', 'UberKms', 'KmsGraph'].includes(step)
          ? { position: 'absolute', bottom: '40px', zIndex: 2 }
          : {}),
      }}
    >
      {/* TopBar */}
      <Box
        sx={{
          paddingTop: '50px',
          gap: '10px',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {step === 'SideBar' ? (
          <SideBarStep onNext={onNext} />
        ) : (
          <IconButton
            style={{ backgroundColor: '#FFF', position: 'absolute', zIndex: 1 }}
            iconProps={{ color: azulTucar }}
          />
        )}

        <Box
          sx={{
            flex: 1,
            display: 'flex',
            gap: '12px',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Typography color={azulTucar} style={{ fontSize: '14px', fontWeight: 600 }}>
            01/09 - 07/09
          </Typography>
          {step === 'Calendar' ? (
            <CalendarStep onNext={onNext} />
          ) : (
            <IconButton
              style={{ backgroundColor: '#E3EDF7' }}
              iconProps={{ color: azulTucar, name: 'calendarTodayOutlined', size: '18px' }}
            />
          )}
        </Box>
        <IconButton
          variant="ghost"
          iconProps={{ name: 'notificationsNoneOutlined', color: '#17171C' }}
        />
      </Box>

      <Box sx={{ marginTop: '23px' }}>
        <Box sx={{ display: 'flex', gap: '5px' }}>
          <Typography color={azulTucar} style={{ fontSize: '18px', fontWeight: 500 }}>
            Hola,
          </Typography>
          <Typography color={azulTucar} style={{ fontSize: '18px', fontWeight: 600 }}>
            {firstname[0].toUpperCase() + firstname.slice(1).toLowerCase()}
          </Typography>
          <Emoji symbol="🖖" label="emoji" />
        </Box>

        <Box>
          <Typography color="#5B5D71" style={{ fontSize: '14px', fontWeight: 500 }}>
            Turno AM (00:00 a 23:59 hrs.)
          </Typography>
        </Box>
      </Box>

      {/* Buttons  */}
      {step === 'Waiting' && <WaitingStep onNext={onNext} />}

      <Box sx={{ display: 'flex', gap: '15px', paddingTop: '35px' }}>
        <Card style={{ padding: '24px 16px', width: '100%' }}>
          <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            <PressIconButton>
              <Icon name="carRentalFilled" color={azulTucar} size="36px" />
            </PressIconButton>
            <Box sx={{ display: 'flex', flexDirection: 'column', paddingInline: '20px' }}>
              <Typography
                color="#5B5D71"
                style={{ fontSize: '16px', fontWeight: 600, lineHeight: 1.2 }}
              >
                En lista de espera
              </Typography>
              <Typography
                color="#5B5D71"
                style={{
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: 1.2,
                  marginBottom: '16px',
                  marginTop: '5px',
                }}
              >
                Estamos asignando un un vehículo para ti.
              </Typography>
              <Button size="small" style={{ width: 'max-content', alignSelf: 'end' }}>
                <Typography color="#5B5D71" style={{ fontSize: '14px', fontWeight: 500 }}>
                  Ver más
                </Typography>
              </Button>
            </Box>
          </Box>
        </Card>
      </Box>

      {/* Profits */}
      {step === 'Gainings' && <GainingStep onNext={onNext} />}

      <Card style={{ gap: '20px', marginTop: '22px', padding: '22px', alignItems: 'unset' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography color="#5B5D71" style={{ fontSize: '16px', fontWeight: 500 }}>
            Ganancias total
          </Typography>
          <Typography color={azulTucar} style={{ fontSize: '36px', fontWeight: 600 }}>
            $123.292
          </Typography>
        </Box>
        <Box sx={{ width: '100%', display: 'flex', gap: '5px', justifyContent: 'space-evenly' }}>
          <IconInfo
            title="Efectivo"
            iconName="moneyOutlined"
            color="#95D31C"
            amount={currentData.totalCash || 0}
          />
          <IconInfo
            title="Recaudaciones"
            iconName="electricCarFilled"
            color="#FABB03"
            amount={currentData.totalCollections || 0}
          />
          <IconInfo
            title="Descuentos"
            iconName="electricCarFilled"
            color="#E81F00"
            amount={currentData.totalExpenses || 0}
          />
        </Box>
        <Button style={{ width: 'max-content', alignSelf: 'end' }}>
          <Typography color={azulTucar} style={{ fontSize: '14px', fontWeight: 600 }}>
            Ver detalle
          </Typography>
        </Button>
      </Card>

      {/* shortCut buttons  */}
      {step === 'Shortcuts' && <ShortcutStep onNext={onNext} />}
      <Box
        sx={{
          display: 'flex',
          gap: '15px',
          marginTop: '30px',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <Button size="small" style={{ padding: '12px 16px' }} contentStyle={{ columnGap: '4px' }}>
          <Icon name="cardTravelFilled" color="#5B5D71" size="14px" />
          <Typography color="#5B5D71" style={{ fontSize: '14px', fontWeight: 500 }}>
            Viajes
          </Typography>
        </Button>
        <Button size="small" style={{ padding: '12px 16px' }} contentStyle={{ columnGap: '4px' }}>
          <Icon name="directionsCarOutlined" color="#5B5D71" size="14px" />
          <Typography color="#5B5D71" style={{ fontSize: '14px', fontWeight: 500 }}>
            TAG
          </Typography>
        </Button>
        <Button size="small" style={{ padding: '12px 16px' }} contentStyle={{ columnGap: '4px' }}>
          <Icon name="attachMoneyOutlined" color="#5B5D71" size="14px" />
          <Typography color="#5B5D71" style={{ fontSize: '14px', fontWeight: 500 }}>
            Boletas
          </Typography>
        </Button>
      </Box>
      {/* km uber % card  */}
      {step === 'UberKms' && <UberKmStep onNext={onNext} />}

      <Card
        style={{
          padding: '12px 16px',
          marginTop: '30px',
          gap: '20px',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <PressIconButton>
          <Icon name="equalizerRounded" color="#95D31C" size="36px" />
        </PressIconButton>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography color="#5B5D71" style={{ fontSize: '15px', fontWeight: 600 }}>
            El {currentData.uberPercentage}% de tus kilómetros
          </Typography>
          <Typography color="#5B5D71" style={{ fontSize: '14px', fontWeight: 500 }}>
            Han sido en viajes de Uber
          </Typography>
        </Box>
      </Card>
      {/* km total trips  */}
      {step === 'KmsGraph' && <GraphStep onNext={onNext} />}
      <Box>
        <Box
          sx={{
            marginTop: '34px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography
            color={azulTucar}
            style={{
              fontSize: '24px',
              fontWeight: 600,
              textAlign: 'center',
            }}
          >
            192.029 KM
          </Typography>
        </Box>
        {/* km graph trips  */}

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: '20px',
            transform: 'translateY(-40px)',
          }}
        >
          {currentData.kmByDay.length > 0 && (
            <BarGraph
              data={currentData.kmByDay}
              step={5}
              barWidth="18px"
              gap="20px"
              total={100}
              maxWidth="max-content"
              maxHeight="250px"
              labelType="km"
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

BaseDashboard.propTypes = {
  step: PropTypes.string,
  onNext: PropTypes.func,
};

BaseDashboard.defaultProps = {
  step: '',
  onNext: () => {},
};

export default BaseDashboard;
