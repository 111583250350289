import { makeRequest } from '../../helpers/requestHelpers';
import baseParams from './config';

const revokeToken = async (token, { onError, onSuccess }) => {
  const { client_id: clientId } = baseParams;
  return makeRequest(`${process.env.ACCOUNT_SERVICE_URL}/api/v1/oauth/revoke`, {
    body: { client_id: clientId, token },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    requestType: 'POST',
    onSuccess,
    onError,
  });
};

export default revokeToken;
