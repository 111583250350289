import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Dropdown, TextField, Button } from '@tucar/pit-stop';

import { rutValidator } from '../../../helpers/rutValidador';
import { validateEmail } from '../../../helpers/auxFunctions';
import banks from '../../../helpers/banks';

const BankAccountForm = ({ values, setValues, handleSend }) => {
  const [emailError, setEmailError] = useState(false);

  const handleChange = (name, event) => {
    if (name === 'email' && emailError) {
      setEmailError(false);
    }
    setValues((currentValues) => ({
      ...currentValues,
      [name]: event,
    }));
  };

  const isNotEmptyObject = (obj) => {
    return Object.values(obj).every((value) => value !== '');
  };

  const checkSend = () => {
    if (validateEmail(values.email)) {
      setEmailError(false);
      handleSend();
    } else {
      setEmailError(true);
    }
  };

  function avaliableSubmit() {
    if (isNotEmptyObject(values) && rutValidator(values.rut) && !emailError) {
      return true;
    }
    return false;
  }

  const types = !values.bank ? [] : banks.filter((bank) => bank.name === values.bank)[0].types;

  return (
    <Box sx={{ paddingTop: '10px', paddingBottom: '20px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
        <Dropdown
          fullWidth
          label="Banco"
          placeholder="Seleccionar"
          value={values.bank}
          options={banks.map((elem) => elem.name)}
          onChange={(value) => handleChange('bank', value)}
        />

        <Dropdown
          fullWidth
          disabled={!values.bank}
          label="Tipo de cuenta"
          placeholder="Seleccionar"
          value={values.accountType}
          options={types.map((elem) => elem.name)}
          onChange={(value) => handleChange('accountType', value)}
        />

        <TextField
          required
          fullWidth
          size="large"
          label="Número de cuenta"
          mask={values?.accountType === 'Cuenta Rut' ? '00000000' : '0000 0000 0000 0000'}
          value={values.account}
          onChange={(value) => handleChange('account', value)}
        />

        <TextField
          required
          size="large"
          fullWidth
          label="Nombre del titular"
          value={values.name}
          onChange={(value) => handleChange('name', value)}
        />

        <TextField
          required
          size="large"
          maskValue
          fullWidth
          mask="00.000.000-#"
          definition={{ '#': /[0-9Kk]/ }}
          label="Rut del titular"
          value={values.rut}
          helperType="error"
          helperText={values.rut && !rutValidator(values.rut) ? 'Rut inválido' : ''}
          onChange={(value) => handleChange('rut', value)}
        />

        <TextField
          required
          size="large"
          fullWidth
          type="email"
          label="Correo electrónico"
          value={values.email}
          helperType="error"
          helperText={emailError ? 'email inválido' : ''}
          onChange={(value) => handleChange('email', value)}
        />

        <Box sx={{ marginTop: '10px' }}>
          <Button
            contentStyle={{ justifyContent: 'center' }}
            fullWidth
            disabled={!avaliableSubmit()}
            onClick={checkSend}
          >
            Guardar Cuenta
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

BankAccountForm.propTypes = {
  setValues: PropTypes.func.isRequired,
  handleSend: PropTypes.func.isRequired,
  values: PropTypes.shape({
    bank: PropTypes.string,
    accountType: PropTypes.string,
    account: PropTypes.string,
    name: PropTypes.string,
    rut: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

export default BankAccountForm;
