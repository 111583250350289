import * as Sentry from '@sentry/react';
import { authStore } from './model';
import { resetDriver } from '../driver';
import { resetGlobal } from '../global';
import { resetDocs } from '../docs';
import { resetLead } from '../lead';
import { resetPlan } from '../plan';

const getExpirationDate = () => new Date().getTime() + 45 * 60 * 1000;

export const setExpirationDate = () => localStorage.setItem('exdt', getExpirationDate());

export const setRefreshToken = (refreshToken) => localStorage.setItem('rtn', refreshToken);

export const setAuthenticated = (authenticated) => localStorage.setItem('iad', authenticated);

export const setAuthProvider = (authProvider) => localStorage.setItem('authProvider', authProvider);

export const setAuth = ({ access_token: accessToken, refresh_token: refreshToken }) => {
  setExpirationDate();
  setRefreshToken(refreshToken);
  setAuthProvider('Bearer');
  authStore({ ...authStore(), accessToken });
};

export const setUserId = (userId) => {
  const currentAuthStore = authStore();
  Sentry.setContext('Driver', { userId });
  authStore({ ...currentAuthStore, data: { ...currentAuthStore.data, uid: userId } });
};

export const initAuthentication = ({ userId }) => {
  setAuthenticated(true);
  setUserId(userId);
};

export const setSession = ({ userId, userFirstName, userLastName, email, verified }) => {
  Sentry.setContext('Driver', { userId, name: `${userFirstName} ${userLastName}`.trim() });
  const currentAuthStore = authStore();
  authStore({
    ...currentAuthStore,
    data: {
      ...currentAuthStore.data,
      uid: userId,
      firstname: userFirstName,
      lastname: userLastName,
      verified,
      email,
    },
  });
};

export const logout = ({ hardLogout } = { hardLogout: false }) => {
  window.Intercom('shutdown');
  const preLabel = localStorage.getItem('hiddeNewVersionFormModal');
  const preUpdate = localStorage.getItem('updateAtFormModal');
  const preLabelModal = localStorage.getItem('hiddeNewVersionModal');
  const preHiddeNews = localStorage.getItem('hiddeNews');
  localStorage.clear();
  localStorage.setItem('hiddeNewVersionModal', preLabelModal);
  localStorage.setItem('hiddeNewVersionFormModal', preLabel);
  localStorage.setItem('updateAtFormModal', preUpdate);
  localStorage.setItem('hiddeNews', preHiddeNews);
  if (hardLogout) {
    authStore({
      ...authStore(),
      accessToken: false,
      data: {
        email: false,
        firstname: false,
        lastname: false,
        uid: false,
        userType: false,
        verified: false,
      },
    });
  }
  resetDriver();
  resetGlobal();
  resetDocs();
  resetLead();
  resetPlan();
};
