import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, Dropdown, Button, Typography, DatePicker } from '@tucar/pit-stop';
import { getTimeDifference } from '@tucar/pit-stop/dist/utils/dateUtils';
import { Box } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { getAuthStore, getDriverStore } from '../../../graphql/store';
import { rutValidator } from '../../../helpers/rutValidador';
import country from '../../../helpers/bd/chile';
import TucarLocationInputField from './TucarLocationInputField';

export const getAge = (dateString) => {
  try {
    const birthDate = new Date(dateString);
    const age = Math.trunc(getTimeDifference(birthDate).days / 365);
    return age;
  } catch (er) {
    return 0;
  }
};

const translateGender = (value) => {
  switch (value) {
    case 'female':
      return 'Femenino';
    case 'male':
      return 'Masculino';
    default:
      return 'Prefiero no decir';
  }
};

const translateParking = (value) => {
  switch (value) {
    case '0':
      return 'No';
    case '1':
      return 'Si';
    default:
      return 'No';
  }
};

export default function InfoForm({ executeMutation }) {
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);
  const { info } = useReactiveVar(getDriverStore);
  const [selectedDate, setSelectedDate] = useState(info.birth ? new Date(info.birth) : null);

  const [loadingButton, setLoadingButton] = useState(false);
  const [values, setValues] = useState({
    ...info,
    uid,
  });

  const isEmptyObject = (obj) => {
    const basicDataCheck = [
      'rut',
      'gender',
      'birth',
      'commune',
      'city',
      'region',
      'address',
      'adrapt',
      'adrnumber',
      'parking',
    ];
    const aux = { ...obj };
    if (selectedDate === null || getAge(selectedDate) < 23) {
      return false;
    }
    if (values.region !== 'Metropolitana de Santiago') {
      return false;
    }
    return basicDataCheck.every((key) => {
      return aux[key] !== undefined && aux[key] !== null && aux[key] !== '';
    });
  };

  const regions = country.map((elem) => elem.name);
  const provinces = !values.region
    ? []
    : country.filter((region) => region.name === values.region)[0]?.provincias || [];

  const communes = !values.city
    ? []
    : provinces.filter((citys) => citys.name === values.city)[0]?.comunas || [];

  const handleChange = (name, event) => {
    if (name === 'region') {
      setValues((currentValues) => ({
        ...currentValues,
        city: '',
        commune: '',
        [name]: event,
      }));
    } else if (name === 'city') {
      setValues((currentValues) => ({
        ...currentValues,
        commune: '',
        [name]: event,
      }));
    } else {
      setValues((currentValues) => ({
        ...currentValues,
        [name]: event,
      }));
    }
  };

  const handleClick = () => {
    setLoadingButton(true);
    executeMutation({
      ...values,
      birth: selectedDate.toISOString().split('T')[0],
    });
    setLoadingButton(false);
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        gap: '25px',
        padding: '0px 25px 95px 25px',
      }}
    >
      <Typography
        style={{
          fontSize: '28px',
          fontWeight: 600,
          letterSpacing: 0.56,
          lineHeight: 1.0,
          color: '#0057B8',
          paddingBottom: '20px',
        }}
      >
        Datos personales
      </Typography>

      <TextField
        fullWidth
        onlyLabel
        maskValue
        required
        mask="00.000.000-#"
        definition={{ '#': /[0-9Kk]/ }}
        label="RUT"
        value={values.rut}
        helperType="error"
        helperText={!rutValidator(values.rut) && 'Rut inválido'}
        onChange={(value) => handleChange('rut', value.toUpperCase())}
      />

      <Box>
        <DatePicker
          fullWidth
          disableFuture
          value={selectedDate}
          label="Fecha"
          onChange={(date) => {
            setSelectedDate(date);
          }}
          views={['year', 'month', 'date']}
          style={{ width: '100%' }}
        />

        <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center', mt: '13px' }}>
          <ErrorOutlineIcon sx={{ fontSize: '20px', color: '#0057B8' }} />
          <Typography
            style={{ color: '#0057B8', fontSize: '14px', lineHeight: 1.2, fontWeight: 500 }}
          >
            Debes ser mayor de 23 años
          </Typography>
        </Box>
      </Box>

      <Dropdown
        fullWidth
        label="Género"
        value={values.gender}
        options={['female', 'male', 'other']}
        placeholder="Selecciona"
        getOptionLabel={translateGender}
        onChange={(e) => handleChange('gender', e)}
      />

      <Dropdown
        fullWidth
        label="Estacionamiento privado"
        value={values.parking}
        options={['1', '0']}
        placeholder="Selecciona"
        getOptionLabel={translateParking}
        onChange={(e) => handleChange('parking', e)}
      />

      <Box>
        <Dropdown
          fullWidth
          label="Región"
          value={values.region}
          options={regions}
          placeholder="Selecciona"
          onChange={(e) => handleChange('region', e)}
        />
        <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center', mt: '13px' }}>
          <ErrorOutlineIcon sx={{ fontSize: '20px', color: '#0057B8' }} />
          <Typography
            style={{ color: '#0057B8', fontSize: '14px', lineHeight: 1.2, fontWeight: 500 }}
          >
            Debes vivir en Santiago de Chile para continuar
          </Typography>
        </Box>
      </Box>

      <Dropdown
        fullWidth
        label="Provincia"
        value={values.city}
        options={provinces.map((elem) => elem.name)}
        placeholder="Selecciona"
        onChange={(e) => handleChange('city', e)}
      />

      <Dropdown
        fullWidth
        label="Comuna"
        value={values.commune}
        options={communes.map((elem) => elem.name)}
        placeholder="Selecciona"
        onChange={(e) => handleChange('commune', e)}
      />

      <Box sx={{ paddingBottom: '20px' }}>
        <Box sx={{ paddingBottom: '9px' }}>
          <Typography
            style={{
              color: '#5B5D71',
              fontSize: '14px',
              lineHeight: 1.2,
              fontWeight: 500,
              fontFamily: 'Poppins-Medium',
            }}
          >
            Dirección
          </Typography>
        </Box>

        <TucarLocationInputField
          label="Dirección"
          placeholder="Dirección"
          name="address"
          style={{ flex: '1  1 100%', height: '50px' }}
          value={values.address || ''}
          selectorValue={values.address || ''}
          onChange={(newValue) => {
            handleChange('address', newValue);
          }}
        />
      </Box>

      <TextField
        fullWidth
        onlyLabel
        required
        label="Número"
        value={values.adrnumber}
        helperType="error"
        helperText={values.adrnumber === '' && 'Campo requerido'}
        onChange={(value) => handleChange('adrnumber', value)}
      />

      <TextField
        fullWidth
        onlyLabel
        required
        label="Casa o departamento"
        value={values.adrapt}
        helperType="error"
        helperText={values.adrapt === '' && 'Campo requerido'}
        onChange={(value) => handleChange('adrapt', value)}
      />

      <Button
        style={{ width: 'max-content', alignSelf: 'center' }}
        disabled={loadingButton}
        onClick={handleClick}
      >
        {loadingButton ? 'Cargando' : 'Guardar información'}
      </Button>
    </Box>
  );
}

InfoForm.propTypes = {
  executeMutation: PropTypes.func.isRequired,
};
