import React from 'react';
import { Routes, Navigate, Route } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import GeneralLayout from './layouts/generalLayout';
// Import Pages
import WelcomePage from './pages/auth/welcome';
import IndexPage from './pages';
import NotFound from './components/NotFound';
import OnBoarding from './pages/onBoarding';

import Dashboard from './pages/app/dashBoard';
import Auth from './pages/auth';
import Apps from './pages/app/apps';
import Events from './pages/app/events';
import Moviments from './pages/app/moviments';
import Guarantee from './pages/app/guarantee';
import Payment from './pages/general/payment';

import NewDashboard from './pages/app/dashBoard/newDashboard';
// import SalesforceApp from './components/salesforce';
import IntercomApp from './components/Intercom';
import ErrorView from './pages/general/ErrorView';
import TutorialNewDashboard from './pages/tutorial/dashboard';

import RentQueueStatus from './pages/general/rentQueueStatus';
import ManageShift from './pages/exchange/manageShift';
import InitForm from './pages/exchange/InitForm';
import ManageShared from './pages/exchange/ManageShared';
import TermsAndCondition from './pages/general/Terms&Conditions';
import Vouchers from './pages/app/vouchers';
import TagScreen from './pages/app/tags';
import UberTripScreen from './pages/app/uberTrips';
import ExchangeEvents from './pages/app/events/bySubjects/exchangeEvents';
import ControlEvents from './pages/app/events/bySubjects/controlEvents';
import ExpensesEvents from './pages/app/events/bySubjects/expensesEvents';
import IncomesEvents from './pages/app/events/bySubjects/incomesEvents';
import NewProfile from './pages/appV2/profile';
import NewNotifications from './pages/appV2/notifications';
import NewLiquidations from './pages/appV2/liquidations';
import SubscriptionPlan from './pages/appV2/subscription';
import GainingView from './pages/appV2/gaining';
import CashBlock from './pages/appV2/cashBlock';
import NewShiftPetitions from './pages/appV2/petitions';
import CurrentPetition from './pages/appV2/petitions/currentPetition';
import AcceptScreen from './pages/appV2/petitions/auxScreens/acceptScreen';
import RejectScreen from './pages/appV2/petitions/auxScreens/rejectScreen';
import TutorialPetitions from './pages/tutorial/petitions';
import Chargers from './pages/appV2/chargers';

function Router() {
  return (
    <Routes>
      <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
      <Route element={<GeneralLayout onBoarding />}>
        <Route index element={<IndexPage />} />
        <Route path="404" element={<NotFound />} />
        <Route path="/welcome" element={<WelcomePage />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/signin" element={<IndexPage />} />
        <Route path="/signup" element={<IndexPage />} />
        <Route path="/signin/uber" element={<IndexPage />} />
        <Route path="/signup/uber" element={<IndexPage />} />
      </Route>
      <Route path="/app/*" element={<AuthGuard />}>
        <Route element={<IntercomApp show />}>
          {/* <Route element={<SalesforceApp />}> */}
          <Route element={<GeneralLayout onBoarding />}>
            <Route path="error" element={<ErrorView />} />
            <Route path="onboarding" element={<OnBoarding />} />
          </Route>
        </Route>
        <Route element={<IntercomApp show />}>
          {/* <Route element={<SalesforceApp />}> */}
          <Route element={<GeneralLayout />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="applications" element={<Apps />} />
            <Route path="events" element={<Events />} />
            <Route path="moviments" element={<Moviments />} />
            <Route path="guarantee" element={<Guarantee />} />
            <Route path="payment" element={<Payment />} />
            <Route path="initForm" element={<InitForm />} />
            <Route path="manageshift" element={<ManageShift />} />
            <Route path="manageshare" element={<ManageShared />} />

            <Route path="v2/*">
              <Route path="petitions/*">
                <Route path="" element={<NewShiftPetitions />} />
                <Route path="current" element={<CurrentPetition />} />
                <Route path="success" element={<AcceptScreen />} />
                <Route path="reject" element={<RejectScreen />} />
              </Route>
              <Route path="cash-block" element={<CashBlock />} />
              <Route path="dashboard" element={<NewDashboard />} />
              <Route path="profile" element={<NewProfile />} />
              <Route path="notifications" element={<NewNotifications />} />
              <Route path="rent-queue-status" element={<RentQueueStatus />} />
              <Route path="vouchers" element={<Vouchers />} />
              <Route path="liquidations" element={<NewLiquidations />} />
              <Route path="subscription" element={<SubscriptionPlan />} />
              <Route path="balance" element={<GainingView />} />
              <Route path="tags" element={<TagScreen />} />
              <Route path="trips" element={<UberTripScreen />} />
              <Route path="exchange-events" element={<ExchangeEvents />} />
              <Route path="control-events" element={<ControlEvents />} />
              <Route path="expenses-events" element={<ExpensesEvents />} />
              <Route path="incomes-events" element={<IncomesEvents />} />
              <Route path="chargers" element={<Chargers />} />
              <Route path="tutorial/*">
                <Route path="dashboard" element={<TutorialNewDashboard />} />
                <Route path="petitions" element={<TutorialPetitions />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="404" />} />
    </Routes>
  );
}

export default Router;
