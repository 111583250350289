import React from 'react';
import PropTypes from 'prop-types';

import { BoxFlex, Icon, Typography } from '@tucar/pit-stop';

const StatusStep = ({ completed }) => {
  return (
    <BoxFlex gap="12px" alignItems="center">
      <Icon
        name={completed ? 'checkCircleRounded' : 'errorRounded'}
        size="24px"
        color={completed ? '#7FB418' : '#0057B8'}
      />
      <Typography style={{ fontWeight: 500, color: completed ? '#7FB418' : '#0057B8' }}>
        {completed ? 'Completado' : 'Pendiente'}
      </Typography>
    </BoxFlex>
  );
};

StatusStep.propTypes = {
  completed: PropTypes.bool,
};

StatusStep.defaultProps = {
  completed: false,
};

export default StatusStep;
