import React from 'react';
import PropTypes from 'prop-types';
import { useMutation, useReactiveVar } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { Card, Typography, Icon, Item, Button, TucarTheme } from '@tucar/pit-stop';
import moment from 'moment';
import EDIT_NOTIFICATION from '../../../graphql/mutations/notifications/editNotifications';
import { getAuthStore } from '../../../graphql/store';

moment.locale('es');

const NotiBox = ({ text, redirect, time, read, refetch, notificationId }) => {
  const navigate = useNavigate();
  const [readNotification] = useMutation(EDIT_NOTIFICATION.mutation, EDIT_NOTIFICATION.policies);
  const {
    data: { uid },
  } = useReactiveVar(getAuthStore);

  return (
    <Card
      onClick={async () => {
        if (!read) {
          await readNotification({ variables: { driverId: uid, notificationId } });
          await refetch();
          navigate(redirect, { replace: true });
        }
      }}
      variant="outlined"
      clicked={read}
    >
      <Box
        style={{
          padding: '31px 29px',
          display: 'flex',
          flexDirection: 'row',
          gap: '20px',
        }}
      >
        <Item style={{ minWidth: '55px', height: '55px', alignSelf: 'start' }}>
          <Icon name="notificationsRounded" />
        </Item>

        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
            overflowWrap: 'anywhere',
          }}
        >
          <Typography variant="p" style={{ fontSize: '14px', color: TucarTheme.colors.gray700 }}>
            {`${moment(time).format('DD/MM/YYYY')}`}
          </Typography>
          <Typography variant="p" style={{ fontSize: '14px', overflowWrap: 'anywhere' }}>
            {text}
          </Typography>
          {!read && (
            <Button
              style={{ padding: 0, WebkitTextFillColor: TucarTheme.colors.blue500 }}
              contentStyle={{ justifyContent: 'start' }}
              variant="ghost"
              size="small"
            >
              Marcar como leído
            </Button>
          )}
        </Box>
      </Box>
    </Card>
  );
};

NotiBox.propTypes = {
  text: PropTypes.string.isRequired,
  redirect: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  read: PropTypes.bool.isRequired,
  refetch: PropTypes.func.isRequired,
  notificationId: PropTypes.string.isRequired,
};

export default NotiBox;
