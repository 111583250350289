import React from 'react';
import { Box } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { getDriverStore } from '../../../../graphql/store';
import CollapseGoals from './CollapseGoals';
import uberProDetails from './uberProDetails';

const UberProGoals = () => {
  const {
    category: { uberPro },
  } = useReactiveVar(getDriverStore);

  return (
    <Box
      sx={{
        marginTop: '20px',
        paddingInline: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        gap: '20px',
      }}
    >
      {uberProDetails.map((elem, index) => {
        const category = uberPro.toLowerCase();
        const keyComponent = `${category}-elem-${index}`;
        const filterDcto = Object.entries(elem.dcto).filter(
          ([key]) => !elem.filter[category].includes(key),
        );
        return (
          <CollapseGoals
            key={keyComponent}
            title={elem.title[category]}
            IconCard={elem.icons}
            color={elem.colors[category]}
            dctos={filterDcto}
            diamond={category === 'diamond'}
          />
        );
      })}
    </Box>
  );
};

export default UberProGoals;
