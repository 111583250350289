import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Collapse } from '@mui/material';
import { KeyboardArrowUpOutlined, KeyboardArrowDownOutlined } from '@mui/icons-material';
import { Typography } from '@tucar/pit-stop';
import translateUberPro from '../../../../helpers/translateUberPro';
import CategoryIcon from '../../../../components/ui/categoryIcon';

const CollapseGoals = ({ title, IconCard, color, dctos, diamond }) => {
  const [open, setOpen] = useState(true);

  return (
    <Box sx={{ paddingBottom: '20px' }}>
      <Box
        onClick={diamond ? () => {} : () => setOpen(!open)}
        sx={{
          display: 'grid',
          gridTemplateColumns: '35px 1fr 20px',
          gap: '5px',
          alignContent: 'center',
        }}
      >
        <IconCard sx={{ color, fontSize: '35px' }} />
        <Typography
          style={{
            fontFamily: 'Poppins-Medium',
            fontSize: '14px',
            alignSelf: 'center',
            lineHeight: 1.2,
          }}
        >
          {title}
        </Typography>

        {open ? (
          <KeyboardArrowUpOutlined
            sx={{
              visibility: diamond ? 'hidden' : 'visible',
              fontSize: '16px',
              alignSelf: 'center',
            }}
          />
        ) : (
          <KeyboardArrowDownOutlined
            sx={{
              visibility: diamond ? 'hidden' : 'visible',
              fontSize: '16px',
              alignSelf: 'center',
            }}
          />
        )}
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{ paddingLeft: '40px' }}>
          <Typography style={{ fontSize: '12px', fontFamily: 'Poppins-Regular' }}>
            Obtén un mejor descuento si eres:
          </Typography>
          {dctos.map((elem) => (
            <Box
              key={`${elem[0]}-detail-${elem[1]}`}
              sx={{ display: 'flex', gap: '10px', paddingLeft: '10px' }}
            >
              <CategoryIcon category={elem[0]} />
              <Typography style={{ fontSize: '12px', fontFamily: 'Poppins-Regular' }}>
                Categoría {translateUberPro(elem[0])}: {elem[1]}
              </Typography>
            </Box>
          ))}
        </Box>
      </Collapse>
    </Box>
  );
};

CollapseGoals.propTypes = {
  title: PropTypes.string.isRequired,
  IconCard: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  color: PropTypes.string,
  dctos: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  diamond: PropTypes.bool,
};

CollapseGoals.defaultProps = {
  color: '#000',
  dctos: [],
  diamond: false,
};

export default CollapseGoals;
