import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ width, height, marginTop, margin, ...props }) => (
  <svg
    className="logo"
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1233.91 359.24"
    {...props}
    style={{
      margin,
      marginTop,
      width,
      height,
    }}
  >
    <defs>
      <style>{'.cls-1{fill:#6cc7dc}.cls-3{fill:#0057b7}'}</style>
    </defs>
    <circle className="cls-1" cx={144.38} cy={339.4} r={19.84} />
    <path
      className="cls-1"
      d="M321.82 203.1a142.92 142.92 0 0 0 1.59-21.18 140.9 140.9 0 0 0-16.3-65.92q1.13 2.13 2.17 4.29c-17.2 8.2-47.35 14.53-83.83 17.39a39.45 39.45 0 0 0-28.15 15.4l-.18.23a19.91 19.91 0 0 1-26 4.53 19.58 19.58 0 0 1-5.19-4.66 39.91 39.91 0 0 0-28.23-15.5c-36.49-2.86-66.63-9.19-83.83-17.39.69-1.44 1.42-2.87 2.17-4.29a140.9 140.9 0 0 0-16.3 65.94 142.92 142.92 0 0 0 1.59 21.18c14.48-7.47 40.15-13.58 72.15-17.2A43.37 43.37 0 0 1 161.7 229v14a19.88 19.88 0 0 0 39.75 0v-14a43.37 43.37 0 0 1 48.22-43.11c32 3.63 57.67 9.74 72.15 17.21Z"
    />
    <path
      d="M93.84 337.32a19.73 19.73 0 0 1-10.77-3.19A181.43 181.43 0 0 1 0 181.57 181.53 181.53 0 0 1 310 53.18a181.68 181.68 0 0 1 30.38 216.55 182.89 182.89 0 0 1-60.27 64.4 19.87 19.87 0 0 1-21.58-33.37 141.47 141.47 0 0 0 64.91-119.19c0-78.2-63.63-141.83-141.84-141.83S39.74 103.37 39.74 181.57a141.46 141.46 0 0 0 64.91 119.19 19.87 19.87 0 0 1-10.81 36.56Z"
      style={{
        fill: '#0057b8',
      }}
    />
    <path
      className="cls-3"
      d="M554.61 159.48h-36.43v67.06q0 8.39 4.19 12.25t12.26 4.35a180.89 180.89 0 0 0 20-.16v37.72q-42.87 4.83-60.45-8.06t-17.57-46.1v-67.06h-28v-40h28c0-46.75 41.59-45.14 41.59-45.14v45.16h36.43ZM734.51 119.5v161.2h-41.59v-18.05q-15.15 22.56-48 22.56-26.45 0-43.69-17.73t-17.25-49v-99h41.59v93.82q0 16.13 8.71 24.66t23.21 8.55q16.11 0 25.79-10t9.67-30v-45.42a41.59 41.59 0 0 1 41.56-41.59ZM1068 143.41l-3.94-4.89Q1045.3 115 1011.46 115q-32.25 0-55.3 24.66t-23 60.45q0 35.79 23 60.45t55.3 24.66q33.84 0 52.55-23.53v19h41.59V119.5a41.58 41.58 0 0 0-37.6 23.91Zm-16.68 89.41q-12.74 12.75-32.08 12.74t-31.92-12.74Q974.7 220.1 974.7 200.1t12.57-32.72q12.59-12.74 31.92-12.74t32.08 12.74Q1064 180.12 1064 200.1t-12.73 32.72ZM1204.09 124q-13.38 7.74-19.18 23.22l-1.16 4.13a41.61 41.61 0 0 0-40.41-31.85v161.2h41.59v-77q0-23.22 15-33.21t34-7.74v-46.47a58.43 58.43 0 0 0-29.84 7.72ZM849.48 285.24q-36.43 0-60.77-24.51t-24.34-60.61q0-36.1 24.34-60.61T849.48 115a83.55 83.55 0 0 1 42.88 11.28 75 75 0 0 1 29.34 30.32l-17.23 10.09a26 26 0 0 1-29-2.1 38.87 38.87 0 0 0-4.19-2.83 42.61 42.61 0 0 0-22.12-5.76q-18.7 0-30.95 12.41T806 200.12q0 19 12.25 31.44T849.16 244a44.25 44.25 0 0 0 22.41-5.64 39.48 39.48 0 0 0 4.49-3 25.76 25.76 0 0 1 28.62-2.09l17.66 10.09a80.64 80.64 0 0 1-30 30.47 82.63 82.63 0 0 1-42.86 11.41Z"
    />
  </svg>
);

Logo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  marginTop: PropTypes.string,
  margin: PropTypes.string,
};

Logo.defaultProps = {
  width: '100%',
  height: '50px',
  margin: 'auto',
  marginTop: '100px',
};
export default Logo;
