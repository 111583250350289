/* eslint-disable import/prefer-default-export */
import { makeVar } from '@apollo/client';

export const authStore = makeVar({
  accessToken: false,
  data: {
    email: false,
    firstname: false,
    lastname: false,
    uid: false,
    userType: false,
    verified: false,
  },
});
